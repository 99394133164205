// DASHBOARD
export const ROOT = ''

export const DASHBOARD_PATH = '/dashboard'
export const REPORT_PATH = '/reports'
export const PURCHASES_PATH = '/purchases'
export const FULFILLMENT_PATH = '/fulfillment'
export const SALES_PATH = '/sales'
export const CROSS_DOCKS_PATH = '/cross-docks'
export const CATALOG_PATH = '/catalog'
export const STOCK_PATH = '/stock'
export const WAREHOUSE_PATH = '/warehouse'
export const SETTING_PATH = '/settings'

export const SIGN_IN = `${ROOT}/signin`
export const SIGN_IN_CONTRACT_NUMBER = `${ROOT}/signin-contract/:contract`
export const SIGN_UP = `${ROOT}/signup`
export const SIGN_UP_CONTRACT_NUMBER = `${ROOT}/signup-contract/:contract`
export const AGGRAK_AUTH = `${ROOT}/v1/oauth2/aggrakid/`
export const AGGRAK_CALLBACK = `${ROOT}/oauth2/aggrakid/callback/`
export const FORGOT_PASSWORD = `${ROOT}/forgot-password`
export const RESET_PASSWORD = `${ROOT}/reset-password/:token`
export const CONFIRMATION = `${ROOT}/confirmation/:contract`
export const INTRODUCTION_GUIDE = `${ROOT}/introduction-guide`
export const PROFILE_PATH = '/profile'
export const PROFILE_DETAIL_PATH = '/profile'
export const AUTH_2_FACTOR_PATH = '/auth-2-factor/:token'

// NOTIFICATIONS
export const NOTIFICATION_LIST_PATH = `/notifications/list/`

// DASHBOARD
export const OVERVIEW_PATH = `${DASHBOARD_PATH}/overview/:tab`

// REPORTS
export const REPORT_LIST_PATH = `${REPORT_PATH}/list`
export const REPORT_STOCK_AGING_LIST_PATH = `${REPORT_PATH}/stock-aging/list`
export const REPORT_SALES_MARGIN_LIST_PATH = `${REPORT_PATH}/sales-margin/list`
export const TPL_BILLING_LIST_PATH = `${REPORT_PATH}/tpl-billing/list`
export const TPL_BILLING_DETAIL_PATH = `${REPORT_PATH}/tpl-billing/:guid/detail`
export const TPL_BILLING_ITEM_PATH = `${REPORT_PATH}/tpl-billing/:guid/item/:type`
export const SALES_OVERVIEW_LIST_PATH = `${REPORT_PATH}/sales-overview/list`
export const SHIPMENT_REPORT_LIST_PATH = `${REPORT_PATH}/shipment/list`
export const UNIT_HISTORY_REPORT_LIST_PATH = `${REPORT_PATH}/unit-history/list`
export const PURCHASE_REPORT_LIST_PATH = `${REPORT_PATH}/purchase/list`
export const SALE_ORDER_REPORT_LIST_PATH = `${REPORT_PATH}/sale-order/list`
export const INVENTORY_REPORT_LIST_PATH = `${REPORT_PATH}/inventory/list`
export const UNIT_REPORT_LIST_PATH = `${REPORT_PATH}/unit/list`
export const TEAM_METRICS_LIST_PATH = `${REPORT_PATH}/team-metrics/list`
export const STORAGE_REPORT_PATH = `${REPORT_PATH}/storage/detail`
export const ORDER_UNITS_REPORT_LIST_PATH = `${REPORT_PATH}/order-units/list`
export const RETAILER_UNIT_REPORT_LIST_PATH = `${REPORT_PATH}/retailer-unit/list`
export const LOCATION_UNIT_REPORT_LIST_PATH = `${REPORT_PATH}/location-unit/list`
export const STOCK_COUNT_REPORT_LIST_PATH = `${REPORT_PATH}/stock-count/list`

// ORDER
export const SALE_ORDER_LIST_PATH = `${SALES_PATH}/sale-order/list`
export const SALE_ORDER_CREATE_PATH = `${SALES_PATH}/sale-order/create`

export const SALE_ORDER_LIST_URL = SALE_ORDER_LIST_PATH
export const SALE_ORDER_DETAIL_PATH = `${SALES_PATH}/sale-order/:guid/detail/:tab`
export const SALE_ORDER_UPDATE_PATH = `${SALES_PATH}/sale-order/:guid/update`
export const SALE_ORDER_IMPORT_PATH = `${SALES_PATH}/sale-order/import`

// CUSTOMER
export const CUSTOMER_LIST_PATH = `${SALES_PATH}/customer/list`
export const CUSTOMER_CREATE_PATH = `${SALES_PATH}/customer/create`
export const CUSTOMER_UPDATE_PATH = `${SALES_PATH}/customer/:guid/update/:tab`
export const CUSTOMER_DETAIL_PATH = `${SALES_PATH}/customer/:guid/detail/:tab`

export const CUSTOMER_LIST_URL = CUSTOMER_LIST_PATH

// PICKLIST
export const PICKLIST_LIST_PATH = `${FULFILLMENT_PATH}/picklist/list`
export const PICKLIST_IMPORT_PATH = `${FULFILLMENT_PATH}/picklist/bulk-upload`
export const PICKLIST_UPDATE_PATH = `${FULFILLMENT_PATH}/picklist/:guid/update/:tab`

export const PICKLIST_LIST_URL = PICKLIST_LIST_PATH

// COMPANY
export const COMPANY_LIST_PATH = `${SETTING_PATH}/company/list`
export const COMPANY_CREATE_PATH = `${SETTING_PATH}/company/create`
export const COMPANY_UPDATE_PATH = `${SETTING_PATH}/company/:id/update/:tab`

export const COMPANY_CREATE_URL = COMPANY_CREATE_PATH

// SUPPLIER
export const SUPPLIER_LIST_PATH = `${PURCHASES_PATH}/supplier/list`
export const SUPPLIER_CREATE_PATH = `${PURCHASES_PATH}/supplier/create`
export const SUPPLIER_DETAIL_PATH = `${PURCHASES_PATH}/supplier/:guid/detail/:tab`
export const SUPPLIER_UPDATE_PATH = `${PURCHASES_PATH}/supplier/:guid/update/:tab`

export const SUPPLIER_LIST_URL = SUPPLIER_LIST_PATH

// PURCHASE_ORDER

export const PURCHASE_ORDER_LIST_PATH = `${PURCHASES_PATH}/purchase-order/list`
export const PURCHASE_ORDER_CREATE_PATH = `${PURCHASES_PATH}/purchase-order/create`

export const PURCHASE_ORDER_LIST_URL = PURCHASE_ORDER_LIST_PATH
export const PURCHASE_ORDER_UPDATE_PATH = `${PURCHASES_PATH}/purchase-order/:guid/update`
export const PURCHASE_ORDER_DETAIL_PATH = `${PURCHASES_PATH}/purchase-order/:guid/detail/:tab`
export const PURCHASE_ORDER_IMPORT_PATH = `${PURCHASES_PATH}/purchase-order/import`

// SUPPLIER_RETURNS

export const SUPPLIER_RETURN_LIST_PATH = `${PURCHASES_PATH}/return/list`
export const SUPPLIER_RETURN_CREATE_PATH = `${PURCHASES_PATH}/return/create`
export const SUPPLIER_RETURN_DETAIL_PATH = `${PURCHASES_PATH}/return/:guid/detail/:tab`
export const SUPPLIER_RETURN_UPDATE_PATH = `${PURCHASES_PATH}/return/:guid/update`

// PRODUCT
export const PRODUCT_LIST_PATH = `${CATALOG_PATH}/product/list`
export const PRODUCT_CREATE_PATH = `${CATALOG_PATH}/product/create`
export const PRODUCT_UPDATE_PATH = `${CATALOG_PATH}/product/:id/update/:tab`
export const PRODUCT_DETAIL_PATH = `${CATALOG_PATH}/product/:id/detail/:tab`
export const PRODUCT_IMPORT_PATH = `${CATALOG_PATH}/product/bulk-upload`

export const PRODUCT_LIST_URL = PRODUCT_LIST_PATH

// VARIANT
export const VARIANT_LIST_PATH = `${CATALOG_PATH}/variant/list`
export const VARIANT_UPDATE_PATH = `${CATALOG_PATH}/variant/:id/update/:productId/:tab`
export const VARIANT_DETAIL_PATH = `${CATALOG_PATH}/variant/:id/detail/:productId/:tab`

export const VARIANT_LIST_URL = VARIANT_LIST_PATH

// CATEGORY
export const CATEGORY_LIST_PATH = `${CATALOG_PATH}/category/list`
export const CATEGORY_CREATE_PATH = `${CATALOG_PATH}/category/create`
export const CATEGORY_UPDATE_PATH = `${CATALOG_PATH}/category/:id/update`
export const CATEGORY_DETAIL_PATH = `${CATALOG_PATH}/category/:id/detail`

// BRAND
export const BRAND_LIST_PATH = `${CATALOG_PATH}/brand/list`
export const BRAND_CREATE_PATH = `${CATALOG_PATH}/brand/create`
export const BRAND_UPDATE_PATH = `${CATALOG_PATH}/brand/:id/update`
export const BRAND_DETAIL_PATH = `${CATALOG_PATH}/brand/:id/detail`

// INTEGRATION
export const INTEGRATION_LIST_PATH = `${SETTING_PATH}/integration/list`
export const SHIPOX_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/shipox`
export const SHIPOX_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/shipox/:tab`
export const API_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/api`
export const API_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/api/:tab`
export const MAGENTO_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/magento`
export const MAGENTO_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/magento/:tab`
export const ZID_INTEGRATION_APPLICATION_CREATE_PATH = `${SETTING_PATH}/integration/application/create/zid`
export const ZID_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/zid`
export const ZID_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/zid/:tab`
export const WOOCOMMERCE_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/woocommerce`
export const WOOCOMMERCE_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/woocommerce/:tab`
export const WOOCOMMERCE_INSTALL_PATH = `${SETTING_PATH}/integration/woocommerce/return`
export const POSTMEN_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/postmen`
export const POSTMEN_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/postmen/:tab`
export const STOREFRONT_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/storefront`
export const STOREFRONT_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/storefront/:tab`
export const SHOPIFY_INSTALL_PATH = `${SETTING_PATH}/integration/shopify/install`
export const SHOPIFY_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/shopify`
export const SHOPIFY_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/shopify/:tab`
export const SHOPIFY_INSTRUCTION_CONTAINER = `${SETTING_PATH}/integration/shopify/instruction`
export const INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/:type`
export const INTEGRATION_TYPE_PATH = `${SETTING_PATH}/integration/types`
export const INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/:type/:tab`
export const SALLA_INSTALL_PATH = `${SETTING_PATH}/integration/salla/install`
export const SALLA_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/salla`
export const SALLA_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/salla/:tab`
export const SALLA_INSTRUCTION_CONTAINER = `${SETTING_PATH}/integration/salla/instruction`
export const SHIPROCKET_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/shiprocket`
export const SHIPROCKET_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/shiprocket/:tab`
export const JT_EXPRESS_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/jnt`
export const JT_EXPRESS_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/jnt/:tab`
export const IMILE_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/imile`
export const IMILE_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/imile/:tab`
export const ELITE_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/elite`
export const ELITE_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/elite/:tab`
export const TCS_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/tcs`
export const TCS_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/tcs/:tab`
export const LCS_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/lcs`
export const LCS_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/lcs/:tab`
export const RIDER_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/rider`
export const RIDER_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/rider/:tab`
export const TRAX_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/trax`
export const TRAX_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/trax/:tab`
export const CALL_COURIER_INTEGRATION_CREATE_PATH = `${SETTING_PATH}/integration/create/call-courier`
export const CALL_COURIER_INTEGRATION_UPDATE_PATH = `${SETTING_PATH}/integration/:guid/update/call-courier/:tab`

// CONFIGURATION
export const CONFIGURATION_PATH = `${SETTING_PATH}/configuration`
export const CONFIGURATION_MENU_PATH = `${CONFIGURATION_PATH}/list`

export const CONDITION_LIST_PATH = `${CONFIGURATION_PATH}/condition/list`

export const CONDITION_CREATE_PATH = `${CONFIGURATION_PATH}/condition/create`
export const CONDITION_UPDATE_PATH = `${CONFIGURATION_PATH}/condition/:id/update`

export const CURRENCY_RATE_LIST_PATH = `${CONFIGURATION_PATH}/currency/list`
export const CURRENCY_RATE_CREATE_PATH = `${CONFIGURATION_PATH}/currency/create`
export const CURRENCY_RATE_UPDATE_PATH = `${CONFIGURATION_PATH}/currency/:guid/update`
// ROLE
export const ROLE_LIST_PATH = `${SETTING_PATH}/role/list`
export const ROLE_CREATE_PATH = `${SETTING_PATH}/role/create`
export const ROLE_UPDATE_PATH = `${SETTING_PATH}/role/:guid/update/:tab`
// ROLE
export const CITY_MAPPING_LIST_PATH = `${SETTING_PATH}/city-mapping/list`
export const CITY_MAPPING_CREATE_PATH = `${SETTING_PATH}/city-mapping/create`
export const CITY_MAPPING_UPDATE_PATH = `${SETTING_PATH}/city-mapping/:id/update`
export const CITY_MAPPING_IMPORT_PATH = `${SETTING_PATH}/city-mapping/import`

// USER
export const USER_LIST_PATH = `${SETTING_PATH}/user/list`
export const USER_CREATE_PATH = `${SETTING_PATH}/user/create`
export const USER_UPDATE_PATH = `${SETTING_PATH}/user/:id/update`
export const USER_DETAIL_PATH = `${SETTING_PATH}/user/:id/detail/:tab`

// ADJUSTMENT
export const ADJUSTMENT_LIST_PATH = `${STOCK_PATH}/adjustment/list`
export const ADJUSTMENT_CREATE_PATH = `${STOCK_PATH}/adjustment/create`
export const ADJUSTMENT_DETAIL_PATH = `${STOCK_PATH}/adjustment/:id/detail/:tab`
export const ADJUSTMENT_IMPORT_PATH = `${STOCK_PATH}/adjustment/import`
export const ADJUSTMENT_UPDATE_PATH = `${STOCK_PATH}/adjustment/:id/update/`

export const ADJUSTMENT_LIST_URL = ADJUSTMENT_LIST_PATH

// PUTAWAY
export const V1_PUTAWAY_LIST_PATH = `${STOCK_PATH}/putaway-v1/list`
export const V2_PUTAWAY_LIST_PATH = `${STOCK_PATH}/putaway-v2/list`
export const V2_PUTAWAY_CREATE_PATH = `${STOCK_PATH}/putaway-v2/create/:guid`
export const V2_PUTAWAY_UPDATE_PATH = `${STOCK_PATH}/putaway-v2/:guid/update`
export const V2_PUTAWAY_DETAIL_PATH = `${STOCK_PATH}/putaway-v2/:guid/detail/:tab/`

// WAREHOUSE
export const WAREHOUSE_LIST_PATH = `${WAREHOUSE_PATH}/list`
export const WAREHOUSE_CREATE_PATH = `${WAREHOUSE_PATH}/create`
export const WAREHOUSE_DETAIL_PATH = `${WAREHOUSE_PATH}/:guid/detail/:tab`
export const WAREHOUSE_UPDATE_PATH = `${WAREHOUSE_PATH}/:guid/update`
export const WAREHOUSE_IMPORT_PATH = `${WAREHOUSE_PATH}/import`
export const WAREHOUSE_VISUALIZATION_PATH = `${WAREHOUSE_PATH}/:guid/visualization`
export const WAREHOUSE_2D_VISUALIZATION_PATH = `${WAREHOUSE_PATH}/:guid/2d/visualization`

// RETAILER WAREHOUSE
export const SHARED_WAREHOUSE_DETAIL_PATH = `${WAREHOUSE_PATH}/shared-warehouse/:guid/detail/:tab`

// AREA
export const AREA_CREATE_PATH = `${WAREHOUSE_PATH}/:warehouseGuid/area/create`
export const AREA_UPDATE_PATH = `${WAREHOUSE_PATH}/area/:guid/update`
export const AREA_DETAIL_PATH = `${WAREHOUSE_PATH}/area/:guid/detail`

// ZONE
export const ZONE_CREATE_PATH = `${WAREHOUSE_PATH}/:warehouseGuid/zone/create`
export const ZONE_UPDATE_PATH = `${WAREHOUSE_PATH}/zone/:guid/update`
export const ZONE_DETAIL_PATH = `${WAREHOUSE_PATH}/zone/:guid/detail`

// LOCATION
export const LOCATION_CREATE_PATH = `${WAREHOUSE_PATH}/:warehouseGuid/location/create`
export const LOCATION_UPDATE_PATH = `${WAREHOUSE_PATH}/location/:guid/update`
export const LOCATION_DETAIL_PATH = `${WAREHOUSE_PATH}/location/:guid/detail/:tab`

// CONTAINER
export const CONTAINER_LIST_PATH = `${WAREHOUSE_PATH}/container/list`
export const CONTAINER_CREATE_PATH = `${WAREHOUSE_PATH}/:warehouseGuid/container/create`
export const CONTAINER_DETAIL_PATH = `${WAREHOUSE_PATH}/container/:guid/detail`

export const CONTAINER_LIST_URL = CONTAINER_LIST_PATH

// RECEIVING
export const RECEIVE_LIST_PATH = `${STOCK_PATH}/receive/list`
export const RECEIVE_TRANSFER_DETAIL_PATH = `${STOCK_PATH}/receive/:guid/transfer/update/:tab`
export const RECEIVE_PURCHASE_ORDER_DETAIL_PATH = `${STOCK_PATH}/receive/:guid/purchase-order/update/:tab`
export const RECEIVE_RETURN_DETAIL_PATH = `${STOCK_PATH}/receives/:guid/returns/update/:tab`

export const RECEIVE_PO_SCAN_DETAIL_PATH = `${STOCK_PATH}/receive/scan/:guid/purchase-order/update`
export const RECEIVE_ST_SCAN_DETAIL_PATH = `${STOCK_PATH}/receive/scan/:guid/transfer/update`
export const RECEIVE_RT_SCAN_DETAIL_PATH = `${STOCK_PATH}/receive/scan/:guid/return/update`

// PACKING
export const PACKING_LIST_PATH = `${FULFILLMENT_PATH}/packing/list`
export const PACKING_UPDATE_PATH = `${FULFILLMENT_PATH}/packing/:id/update`
export const PACKING_DETAIL_PATH = `${FULFILLMENT_PATH}/packing/:id/detail/:tab`

// STOCK TRANSFER
export const TRANSFER_LIST_URL = `${STOCK_PATH}/transfer/list`
export const TRANSFER_LIST_PATH = `${STOCK_PATH}/transfer/list`
export const TRANSFER_CREATE_PATH = `${STOCK_PATH}/transfer/create`
export const TRANSFER_UPDATE_PATH = `${STOCK_PATH}/transfer/:guid/update`
export const TRANSFER_DETAIL_PATH = `${STOCK_PATH}/transfer/:guid/detail/:tab`

// ADJUSTMENT REASONS
export const ADJUSTMENT_REASON_LIST_PATH = `${CONFIGURATION_PATH}/adjustment-reason/list`
export const ADJUSTMENT_REASON_CREATE_PATH = `${CONFIGURATION_PATH}/adjustment-reason/create`
export const ADJUSTMENT_REASON_UPDATE_PATH = `${CONFIGURATION_PATH}/adjustment-reason/:id/update`

// CONTAINER_TYPE
export const CONTAINER_TYPE_LIST_PATH = `${CONFIGURATION_PATH}/container-type/list`
export const CONTAINER_TYPE_CREATE_PATH = `${CONFIGURATION_PATH}/container-type/create`
export const CONTAINER_TYPE_UPDATE_PATH = `${CONFIGURATION_PATH}/container-type/:id/update`

export const CONTAINER_TYPE_CREATE_URL = CONTAINER_TYPE_CREATE_PATH

// BUCKETS
export const BUCKETS_LIST_PATH = `${CROSS_DOCKS_PATH}/buckets/list`
export const BUCKETS_UPDATE_PATH = `${CROSS_DOCKS_PATH}/buckets/:guid/update`
export const BUCKETS_CREATE_PATH = `${CROSS_DOCKS_PATH}/buckets/create`

// LOT SHEETS
export const LOT_SHEETS_LIST_PATH = `${CROSS_DOCKS_PATH}/lot-sheets/list`
export const LOT_SHEETS_IMPORT_PATH = `${CROSS_DOCKS_PATH}/lot-sheets/import`
export const LOT_SHEETS_DETAIL_PATH = `${CROSS_DOCKS_PATH}/lot-sheets/:guid/detail/:tab`
export const LOT_SHEETS_SCAN_PATH = `${CROSS_DOCKS_PATH}/lot-sheets/:guid/scan/:lineItemGuid`
export const LOT_SHEETS_SHIPMENT_PATH = `${CROSS_DOCKS_PATH}/lot-sheets/:guid/shipment`

// CROSS SHIPMENT
export const CROSS_SHIPMENT_LIST_PATH = `${CROSS_DOCKS_PATH}/shipment/list`

// CONTRACT_TYPE
export const CONTRACT_LIST_PATH = `${CONFIGURATION_PATH}/contract/list`
export const CONTRACT_CREATE_PATH = `${CONFIGURATION_PATH}/contract/create`
export const CONTRACT_CREATE_WITH_RETAILER_PATH = `${CONFIGURATION_PATH}/contract/create-with-retailer`
export const CONTRACT_UPDATE_PATH = `${CONFIGURATION_PATH}/contract/:id/update/:tab`
export const CONTRACT_INVOICE_DETAIL_PATH = `${CONFIGURATION_PATH}/contract/invoice/:contractGuid/detail/:guid`

// UNIT NUMBERS
export const UNIT_LIST_PATH = `${CATALOG_PATH}/unit/list`
export const UNIT_HISTORY_PATH = `${CATALOG_PATH}/unit/history`
export const UNIT_LIST_URL = `${CATALOG_PATH}/unit/list`

// SHIPMENT
export const SHIPMENT_LIST_PATH = `${FULFILLMENT_PATH}/shipments/list`
export const SHIPMENT_UPDATE_PATH = `${FULFILLMENT_PATH}/shipments/:guid/update`
export const SHIPMENT_DETAIL_PATH = `${FULFILLMENT_PATH}/shipments/:guid/detail/:tab`

// ACCOUNT
export const ACCOUNT_UPDATE_PATH = `${SETTING_PATH}/account`

// MOVEMENT
export const MOVEMENT_LIST_PATH = `${STOCK_PATH}/movement/list`
export const MOVEMENT_BROWSE_CREATE_PATH = `${STOCK_PATH}/movement/create/browse`
export const MOVEMENT_BROWSE_UPDATE_PATH = `${STOCK_PATH}/movement/:id/update/browse`
export const MOVEMENT_SCAN_CREATE_PATH = `${STOCK_PATH}/movement/create/scan`
export const MOVEMENT_SCAN_UPDATE_PATH = `${STOCK_PATH}/movement/:id/update/scan`
export const MOVEMENT_DETAIL_PATH = `${STOCK_PATH}/movement/:id/detail/:tab`
export const MOVEMENT_IMPORT_PATH = `${STOCK_PATH}/movement/import`

// RETURN
export const RETURN_LIST_PATH = `${SALES_PATH}/return/list`
export const RETURN_CREATE_PATH = `${SALES_PATH}/return/create/:guid`
export const RETURN_UPDATE_PATH = `${SALES_PATH}/return/:guid/update/:tab`
export const RETURN_DETAIL_PATH = `${SALES_PATH}/return/:guid/detail/:tab`

// AUTOMATION
export const AUTOMATION_CREATE_PATH = `${CONFIGURATION_PATH}/automation/create`
export const AUTOMATION_LIST_PATH = `${CONFIGURATION_PATH}/automation/list`
export const AUTOMATION_UPDATE_PATH = `${CONFIGURATION_PATH}/automation/:guid/update`

// INVENTORY LOG
export const INVENTORY_LOG_LIST_PATH = `${STOCK_PATH}/inventory-log/list`

// INVENTORY LOG
export const REORDER_LIST_PATH = `${STOCK_PATH}/reorder/list`

// STOCK COUNT
export const STOCK_COUNT_LIST_PATH = `${STOCK_PATH}/stock-count/list`
export const STOCK_COUNT_CREATE_PATH = `${STOCK_PATH}/stock-count/create`
export const STOCK_COUNT_UPDATE_PATH = `${STOCK_PATH}/stock-count/:guid/update`

// BILLING PROFILE

export const BILLING_PROFILE_LIST_PATH = `${CONFIGURATION_PATH}/billing-profile/list`
export const BILLING_PROFILE_CREATE_PATH = `${CONFIGURATION_PATH}/billing-profile/create/:tab`
export const BILLING_PROFILE_UPDATE_PATH = `${CONFIGURATION_PATH}/billing-profile/:guid/update/:tab`

// BILLING
export const BILLING_DETAIL_PATH = `${SETTING_PATH}/billing/:guid/detail`
export const BILLING_LIST_PATH = `${SETTING_PATH}/billing/list`
export const BILLING_ITEM_PATH = `${SETTING_PATH}/billing/:guid/item/:type`

// BUNDLES
export const BUNDLE_LIST_PATH = `${CATALOG_PATH}/bundle/list`
export const BUNDLE_CREATE_PATH = `${CATALOG_PATH}/bundle/create`
export const BUNDLE_UPDATE_PATH = `${CATALOG_PATH}/bundle/:guid/update/:tab`
export const BUNDLE_DETAIL_PATH = `${CATALOG_PATH}/bundle/:guid/detail/:tab`

// SO-INVOICES
export const INVOICE_LIST_PATH = `${SALES_PATH}/invoice/list`
export const INVOICE_DETAIL_PATH = `${SALES_PATH}/invoice/:guid/detail`

// BARCODE-GENERATOR
export const BARCODE_GENERATOR_PATH = `${SETTING_PATH}/barcode/generate`

// TAXES
export const TAX_LIST_PATH = `${CONFIGURATION_PATH}/tax/list`
export const TAX_CREATE_PATH = `${CONFIGURATION_PATH}/tax/create`
export const TAX_UPDATE_PATH = `${CONFIGURATION_PATH}/tax/:guid/update`

// ERROR GENERAL
export const ERROR_DEFINITION = `/error/codes/:code`

// DELIVERY METHOD
export const DELIVERY_METHOD_LIST_PATH = `${CONFIGURATION_PATH}/delivery-method/list`
export const DELIVERY_METHOD_CREATE_PATH = `${CONFIGURATION_PATH}/delivery-method/create`
export const DELIVERY_METHOD_UPDATE_PATH = `${CONFIGURATION_PATH}/delivery-method/:guid/update`

// LISTING
export const LISTING_LIST_PATH = `${CATALOG_PATH}/listing/list`
export const LISTING_UPDATE_PATH = `${CATALOG_PATH}/listing/:guid/update/:tab`
export const LISTING_DETAIL_PATH = `${CATALOG_PATH}/listing/:guid/detail/:tab`

// TEMPLATES
export const TEMPLATE_LIST_PATH = `${SETTING_PATH}/templates/list`
export const TEMPLATE_UPDATE_PATH = `${SETTING_PATH}/templates/:type/update`

export const AGGRAK_PATH = 'https://app.aggrak.com/'
