import React from 'react'
import PropTypes from 'prop-types'
import { map, path, prop } from 'ramda'
import { useTranslation } from 'react-i18next'

import PageTitle from '~/components/PageTitle'
import TableDateFormat from '~/components/TableDateFormat'
import Container, { Content, Header } from '~/components/Container'
import { Table, TableHeader } from '~/components/Table'
import * as NAV from '~/constants/nav-titles'
import Button from '~/components/Buttons/Button'

import StockCountStatus from './StockCountStatus'

export const SALE_ORDER_COLUMNS = [
  {
    width: 150,
    headerName: 'Number',
    field: 'number',
    flex: 1
  },
  {
    width: 150,
    headerName: 'Warehouse',
    field: 'warehouse',
    flex: 1
  },
  {
    width: 150,
    headerName: 'Created At',
    field: 'createdAt',
    renderCell: ({ value }) => (
      <TableDateFormat withTime={true} date={value} />
    ),
    flex: 1
  },
  {
    width: 150,
    headerName: 'Status',
    field: 'status',
    renderCell: ({ value }) => (
      <StockCountStatus value={value} />
    ),
    flex: 1
  }
]

const getRowsFromResults = map(result => {
  const guid = prop('guid', result)
  const number = prop('number', result)
  const status = prop('status', result)
  const warehouse = path(['warehouse', 'name'], result)
  const createdAt = prop('createdAt', result)

  return {
    guid,
    number,
    status,
    warehouse,
    createdAt
  }
})

function StockCountReportTable (props) {
  const {
    list,
    filter,
    ordering,
    onReportGenerate,
    onListRefetch
  } = props

  const { t } = useTranslation()
  const isLoading = prop('isLoading', list)
  const results = prop('results', list)
  const hasNextPage = prop('hasNextPage', list)
  const hasPrevPage = prop('hasPrevPage', list)
  const count = prop('count', list)
  const columns = prop('filteredColumns', ordering)

  return (
    <Container>
      <Header>
        <PageTitle
          pageTitle={NAV.STOCK_COUNT_REPORT}
          parentTitle={NAV.REPORTS}
          rightButton={(
            <Button
              variant="contained"
              data-cy="create"
              type="button"
              onClick={onReportGenerate}
            >
              {t('Generate Report')}
            </Button>
          )}
        />
      </Header>

      <Content>
        <TableHeader
          filter={filter}
          ordering={ordering}
          isLoading={isLoading}
          filterOpen={filter.handleOpen}
          orderingOpen={ordering.handleOpen}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          getRowsFromResults={getRowsFromResults}
          results={results}
          count={count}
          pinnedColumns={['number']}
          primaryKey="guid"
          ordering={ordering}
          checkboxSelection={false}
          onRefetch={onListRefetch}
          isNewPagination={true}
          hasNextPage={hasNextPage}
          hasPrevPage={hasPrevPage}
        />
      </Content>
    </Container>
  )
}

StockCountReportTable.propTypes = {
  list: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  ordering: PropTypes.object.isRequired,
  onReportGenerate: PropTypes.func.isRequired,
  onListRefetch: PropTypes.func.isRequired
}

export default StockCountReportTable
