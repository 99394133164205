import { AuthLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'

import CityMappingListContainer from './containers/CityMappingListContainer'
import CityMappingCreateContainer from './containers/CityMappingCreateContainer'
import CityMappingUpdateContainer from './containers/CityMappingUpdateContainer'
import CityMappingImportContainer from './containers/CityMappingImportContainer'

export default () => {
  return [
    {
      layout: AuthLayout,
      path: ROUTES.CITY_MAPPING_CREATE_PATH,
      component: CityMappingCreateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CITY_MAPPING_UPDATE_PATH,
      component: CityMappingUpdateContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CITY_MAPPING_LIST_PATH,
      component: CityMappingListContainer
    },
    {
      layout: AuthLayout,
      path: ROUTES.CITY_MAPPING_IMPORT_PATH,
      component: CityMappingImportContainer
    },
  ]
}
