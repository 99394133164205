import React from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell/TableCell'
import TableBody from '@mui/material/TableBody'
import { prop } from 'ramda'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import DeleteIcon from '@mui/icons-material/Delete'
import { DateTime } from 'luxon'

import Avatar from '~/components/Avatar'
import { NAMES } from '~/constants/barcodingStrategies'
import VerticalAlignment from '~/components/VerticalAlignment'
import TextOverflow from '~/components/TextOverflow'
import Subtext from '~/components/Subtext'
import MoneyField from '~/components/Fields/MoneyField'
import TextField from '~/components/Fields/TextField'
import DateField from '~/components/Fields/DateField'
import LocationField from '~/components/Fields/LocationField'
import * as API from '~/constants/api'

import UnitsField from './Fields/UnitsField'

function ItemsTable ({ extraData, unitFieldFunctions, handleRemove }) {
  const { t } = useTranslation()

  const lineItemsField = useField('lineItems')
  const lineItemsFieldValue = lineItemsField.input.value || []

  const unitsColumnTitle = extraData.removeOperation ? 'Remove units' : 'Add units'
  const minDate = DateTime.now()

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 50 }}>{t('Image')}</TableCell>
            <TableCell sx={{ width: 200 }}>{t('Product')}</TableCell>
            <TableCell>{t('Barcoding strategy')}</TableCell>
            <TableCell>{t('Unit cost')}</TableCell>
            <TableCell sx={{ width: 150 }}>{t(unitsColumnTitle)}</TableCell>
            <TableCell>{t('Container number')}</TableCell>
            <TableCell>{t('Expiry date')}</TableCell>
            <TableCell>{t('Production date')}</TableCell>
            <TableCell sx={{ width: 300 }}>{t('Location')}</TableCell>
            <TableCell sx={{ width: 50 }}>{t('Delete')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItemsFieldValue.map((item, index) => {
            const guid = prop('guid', item)
            const variant = prop('variant', item)
            const variantName = prop('name', variant)
            const image = prop('defaultImage', variant)
            const trackExpiry = prop('trackExpiryDates', variant)
            const trackProduction = prop('trackProductionDates', variant)
            const sku = prop('sku', variant)
            const barcodingStrategy = NAMES[prop('barcodingStrategy', variant)]

            return (
              <TableRow key={guid}>
                <TableCell>
                  <Avatar
                    alt={variantName}
                    src={image}
                  />
                </TableCell>
                <TableCell>
                  <VerticalAlignment
                    primary={<TextOverflow selfTooltip={true}>{variantName}</TextOverflow>}
                    secondary={<Subtext>{sku}</Subtext>}
                  />
                </TableCell>
                <TableCell>
                  {barcodingStrategy}
                </TableCell>
                <TableCell>
                  <MoneyField
                    name={`lineItems[${index}].price`}
                    data-cy={`lineItems[${index}].price`}
                  />
                </TableCell>
                <TableCell>
                  <UnitsField
                    id={extraData.id}
                    name={`lineItems[${index}].units`}
                    item={item}
                    units={extraData.units}
                    ownerId={extraData.ownerId}
                    warehouseId={extraData.warehouseId}
                    warehouseGuid={extraData.warehouseGuid}
                    condition={extraData.condition}
                    reasonId={extraData.reasonId}
                    removeOperation={extraData.removeOperation}
                    unitFieldMethods={unitFieldFunctions}
                    buttonDataCy={`lineItems[${index}].units`}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    name={`lineItems[${index}].containerNumber`}
                    data-cy={`lineItems[${index}].containerNumber`}
                    disabled={extraData.removeOperation}
                  />
                </TableCell>
                <TableCell>
                  <DateField
                    data-cy={`lineItems[${index}].expiresAt`}
                    name={`lineItems[${index}].expiresAt`}
                    disabled={!trackExpiry}
                    minDate={minDate}
                    label="Expiry Date"
                  />
                </TableCell>
                <TableCell>
                  <DateField
                    data-cy={`lineItems[${index}].productionDate`}
                    name={`lineItems[${index}].productionDate`}
                    disabled={!trackProduction}
                    label="Production Date"
                  />
                </TableCell>
                <TableCell>
                  <LocationField
                    data-cy={`lineItems[${index}].location`}
                    name={`lineItems[${index}].location`}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        event.preventDefault()
                      }
                    }}
                    label={null}
                    api={API.STOCKS_LOCATION_LIST}
                    params={{ warehouseId: extraData.warehouseId }}
                    disabled={extraData.removeOperation}
                    ListboxProps={{ 'data-cy': 'locationList' }}
                  />
                </TableCell>
                <TableCell>
                  <GridActionsCellItem
                    label="Delete"
                    onClick={() => handleRemove(index)}
                    icon={<DeleteIcon />}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ItemsTable.propTypes = {
  extraData: PropTypes.object.isRequired,
  unitFieldFunctions: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired
}

export default ItemsTable
