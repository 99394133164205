import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { DiscardButton, SaveButton } from '~/components/Buttons'
import { withForm } from '~/components/Form'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'

import SaleOrderForm from '../SaleOrderForm/SaleOrderForm'

function SaleOrderUpdateForm (props) {
  const {
    form,
    pageTitle,
    pageTitleLoading,
    isLoading,
    onCustomerCreate,
    paymentTypeValues,
    saleOrderVariant,
    onFileAttach,
    onFileAttachRemove,
    onGetOrderCondition,
    lineItems,
    setDefaultCondition,
    defaultCondition
  } = props

  const { isRetailer } = useCompany()

  const { initialValues, handleSubmit } = form

  const company = prop('company', initialValues)

  const currency = prop('currency', initialValues)

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Container>
          <Header>
            <PageTitle
              pageTitle={pageTitle}
              pageTitleLoading={pageTitleLoading}
              parentTitle={NAV.SALE_ORDERS}
              rightButton={(
                <>
                  <DiscardButton disabled={isLoading} />
                  <SaveButton disabled={isLoading} />
                </>
              )}
            />
          </Header>
          <Content>
            <SaleOrderForm
              currency={currency}
              isLoading={pageTitleLoading}
              company={company}
              isRetailer={isRetailer}
              values={initialValues}
              onCustomerCreate={onCustomerCreate}
              paymentTypeValues={paymentTypeValues}
              saleOrderVariant={saleOrderVariant}
              onFileAttach={onFileAttach}
              onFileAttachRemove={onFileAttachRemove}
              onGetOrderCondition={onGetOrderCondition}
              prevLineItems={lineItems}
              defaultCondition={defaultCondition}
              setDefaultCondition={setDefaultCondition}
            />
          </Content>
        </Container>
      </form>
    </>
  )
}

SaleOrderUpdateForm.propTypes = {
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  onCustomerCreate: PropTypes.func.isRequired,
  paymentTypeValues: PropTypes.object.isRequired,
  saleOrderVariant: PropTypes.object.isRequired,
  onFileAttach: PropTypes.func.isRequired,
  onFileAttachRemove: PropTypes.func.isRequired,
  onGetOrderCondition: PropTypes.func,
  lineItems: PropTypes.array,
  defaultCondition: PropTypes.object,
  setDefaultCondition: PropTypes.func,
}

export default withForm(SaleOrderUpdateForm)
