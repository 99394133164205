import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import { UploadedFilePlaceholder } from '~/components/FileUpload'

function UploadedFile ({ file }) {
  return (
    <UploadedFilePlaceholder file={file}>
      <Typography>
        You will be importing city mappings
      </Typography>
    </UploadedFilePlaceholder>
  )
}

export default UploadedFile

UploadedFile.propTypes = {
  file: PropTypes.object
}
