import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { useAllSearchParams, useRoute } from 'storfox-route-hooks'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import { length, without, is, isEmpty, uniq, slice } from 'ramda'
import { useLocation } from 'react-router-dom'
import Badge, { badgeClasses } from '@mui/material/Badge'
import MUITextField from '@mui/material/TextField/TextField'
import Chip from '@mui/material/Chip'
import PropTypes from 'prop-types'

const RootStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  right: 0,
  top: 0,
  left: 0,
  background: theme.palette.background.paper,
  zIndex: 999,
  padding:'0 10px'
}))

const BadgeStyled = styled(Badge)(({ badgeContent }) => ({
  [`& .${badgeClasses.badge}`]: {
    ...badgeContent && {
      transform: 'scale(.8) translate(50%, -50%)'
    }
  }
}))

const MUITextFieldStyled = styled(MUITextField)({
  '.MuiInput-root::before': {
    borderBottom: 'unset !important'
  },
  '.MuiInput-root::after': {
    borderBottom: 'unset !important'
  },
  'input': {
    minWidth: '50px'
  }
})

const LOCATION_KEY = 'searchPrevLocation'
const SEARCH_KEY = 'search'

const OPENED = 'opened'
const CLOSED = 'closed'

const setSearchStorage = search => {
  localStorage.setItem(SEARCH_KEY, search)
}

const getSearchStorage = () => {
  return localStorage.getItem(SEARCH_KEY) || CLOSED
}

const setSearchLocation = location => {
  localStorage.setItem(LOCATION_KEY, location)
}

const getKey = event => {
  if (event.key !== 'Unidentified') {
    return event.key
  }

  const ss = event.target.selectionStart - 1
  const ssv = ss || 0
  return event.target.value.substr(ssv, 1)
}

const getChips = tags => is(Array, tags) ? tags : []

function TableSearchField ({ tagSearch }) {
  const { search } = useAllSearchParams()
  const { replaceParams } = useRoute()
  const { pathname } = useLocation()
  const [value, setValue] = useState(search || '')
  const [tags, setTags] = useState([])
  const initialValue = getSearchStorage()
  const [searchState, setSearchState] = useState(initialValue)
  const chips = getChips(tags)

  useEffect(() => {
    if (search && tags.length === 0 && tagSearch) {
      setTags(search.split(','))
      setValue('')
    }
  }, [search, tags, tagSearch])

  const { t } = useTranslation()

  useEffect(() => {
    setSearchState(CLOSED)
    setSearchStorage(CLOSED)
  }, [pathname])

  const handleSearchClear = () => {
    setValue('')
    setTags([])
    replaceParams({ search: '' })
    setSearchState(CLOSED)
    setSearchStorage(CLOSED)
  }

  const handleSearch = () => {
    replaceParams({ search: encodeURIComponent(value.trim()) })
  }

  const handleTagSearch = (newTags = []) => {
    const allTags = uniq([...tags, ...newTags])
    const newSearch = allTags.length ? encodeURIComponent(allTags.join(',')) : ''
    replaceParams({ search: newSearch })
  }

  const searchAfterDelete = (newTags) => {
    replaceParams({ search: encodeURIComponent(newTags.join(',')) })
  }

  const handleSearchClick = event => {
    event.preventDefault()
    if (tagSearch) {
      handleTagSearch()
    } else {
      handleSearch()
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearch()
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      setSearchState(CLOSED)
    }
  }

  const handleSearchOpen = () => {
    setSearchStorage(OPENED)
    setSearchState(OPENED)
    setSearchLocation(pathname)
  }

  const handleSearchClose = () => {
    setSearchStorage(CLOSED)
    setSearchState(CLOSED)
  }

  const onTagBlur = (event) => {
    if (!isEmpty(value)) {
      event.preventDefault()
      setTags(uniq([...tags, value]))
      setValue('')
    }
  }

  const onTagKeyDown = event => {
    const isBackspace = event.key === 'Backspace'
    const isSeparator = event.key === 'Tab' || event.key === 'Enter'

    if (isSeparator && !isEmpty(value)) {
      event.preventDefault()
      if (value.length >= 4) {
        const newTags = uniq([...tags, ...value.split(',')])
        setTags(newTags)
        setValue('')
        handleTagSearch(newTags)
      } else {
        setValue('')
      }
    }

    if (isBackspace && isEmpty(value)) {
      event.preventDefault()
      const afterTags = slice(0, -1, tags)
      setTags(afterTags)
      const newSearch = afterTags.length ? encodeURIComponent(afterTags.join(',')) : ''
      replaceParams({ search: newSearch })
    }
  }

  const onKeyUp = event => {
    const key = getKey(event)
    const isSeparator = key === ','

    if (isSeparator && !isEmpty(value)) {
      event.preventDefault()
      setTags(uniq([...tags, value.replace(/,/g, '')]))
      setValue('')
    }
  }

  const renderAdornment = () => {
    if (chips.length) {
      return chips.map((label, index) => (
        <Chip
          key={index}
          size="small"
          label={label}
          onDelete={() => {
            setTags(without([label], tags))
            searchAfterDelete(without([label], tags))
          }}
          sx={{
            borderRadius: '5px',
            marginRight: '5px',
            marginBottom: '5px',
            marginTop: '5px'
          }}
        />
      )
      )
    }
  }

  return (
    <>
      {searchState === OPENED ? (
        <RootStyled
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton size="small" onClick={handleSearchClick} >
            <SearchIcon size="small" />
          </IconButton>
          {tagSearch && (
            <MUITextFieldStyled
              fullWidth={true}
              name="search"
              data-cy="search"
              variant="standard"
              size="small"
              placeholder={t('Search')}
              InputProps={{
                startAdornment: renderAdornment()
              }}
              value={value}
              onChange={event => setValue(event.target.value)}
              sx={{ overflow: 'hidden', overflowX: 'auto' }}
              onKeyDown={onTagKeyDown}
              autoFocus={true}
              onKeyUp={onKeyUp}
              onBlur={onTagBlur}
            />
          )}
          {!tagSearch && (
            <InputBase
              name="search"
              data-cy="search"
              variant="outlined"
              size="small"
              placeholder={t('Search')}
              value={value}
              autoFocus={true}
              onChange={event => setValue(event.target.value)}
              onKeyDown={handleKeyDown}
              sx={{ width: '100%' }}
              inputProps={{ 'aria-label': 'search' }}
              autoComplete="off"
            />
          )}
          <IconButton size="small" onClick={handleSearchClear}>
            <DeleteIcon />
          </IconButton>
          <IconButton size="small" onClick={handleSearchClose}>
            <CloseIcon />
          </IconButton>
        </RootStyled>
      ) : (
        <IconButton data-cy="searchButton" size="small" onClick={handleSearchOpen} >
          <BadgeStyled
            color="primary"
            badgeContent={search && length(search) ? 1 : 0}
          >
            <SearchIcon size="small" />
          </BadgeStyled>
        </IconButton>
      )}
    </>
  )
}

TableSearchField.propTypes = {
  tagSearch: PropTypes.bool
}

export default React.memo(TableSearchField)
