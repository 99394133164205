import React from 'react'
import PropTypes from 'prop-types'

import Status from '~/components/Status'

export const NEW = 'new'
export const PENDING = 'pending'
export const IN_PROGRESS = 'in_progress'
export const COMPLETED = 'completed'
export const CANCELLED = 'cancelled'

const COLOR = {
  [NEW]: 'success',
  [PENDING]: 'warning',
  [IN_PROGRESS]: 'warning',
  [COMPLETED]: 'success',
  [CANCELLED]: 'error',
}

export const STATUSES = {
  [NEW]: 'New',
  [PENDING]: 'Pending',
  [IN_PROGRESS]: 'In progress',
  [COMPLETED]: 'Completed',
  [CANCELLED]: 'Cancelled'
}

function StockCountStatus ({ value }) {
  return (
    <Status
      label={STATUSES[value]}
      color={COLOR[value]}
    />
  )
}

StockCountStatus.propTypes = {
  value: PropTypes.oneOf([
    NEW,
    PENDING,
    IN_PROGRESS,
    COMPLETED,
    CANCELLED,
  ]).isRequired
}

export default StockCountStatus
