import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import { useField } from 'react-final-form'
import { propOr } from 'ramda'
import { useValueChanged } from 'storfox-tools'

import TagsField from '~/components/Fields/TagsField'
import TextField from '~/components/Fields/TextField'
import { CardHeader } from '~/components/Cards'
import WarehouseSearchField from '~/components/Fields/WarehouseSearchField'
import * as API from '~/constants/api'
import CurrencyField from '~/components/Fields/CurrencyField'
import MoneyField from '~/components/Fields/MoneyField'
import DateField from '~/components/Fields/DateField'
import FileAttachment from '~/components/FileAttachment/FileAttachment'

import LineItems from './LineItems'

import SaleOrderCustomerInfo from '../CustomerForm/SaleOrderCustomerInfo'
import ChannelField from '../Fields/ChannelField'
import InvoiceSummary from '../InvoiceSummary'
import PaymentTypeUpdateField from '../Fields/PaymentTypeUpdateField'
import DeliveryMethodField from '../Fields/DeliveryMethodField'
import CompanySearchField from '../Fields/CompanySearchField'

function SaleOrderForm (props) {
  const {
    values,
    onCustomerCreate,
    paymentTypeValues,
    isLoading,
    currency,
    saleOrderVariant,
    onFileAttach,
    onFileAttachRemove,
    onGetOrderCondition,
    prevLineItems,
    setDefaultCondition,
    defaultCondition
  } = props
  const [updated, setUpdated] = useState(false)

  const lineItemsField = useField('lineItems')
  const lineItems = lineItemsField.input.value

  const totalPriceField = useField('totalPrice')
  const totalPriceChange = totalPriceField.input.onChange
  const totalPrice = totalPriceField.input.value

  const shippingFee = propOr(0, 'shippingFee', values)
  const extraFees = propOr(0, 'extraFees', values)

  useValueChanged(() => {
    const isDifference = JSON.stringify(prevLineItems) !== JSON.stringify(lineItems)
    setUpdated(isDifference)
    if (isDifference) {
      totalPriceChange(0)
    }
  }, [lineItems, prevLineItems])

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12} lg={8}>
        <Card>
          <CardHeader title="Customer" />
          <CardContent>
            <SaleOrderCustomerInfo onCustomerCreate={onCustomerCreate} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={4}>
        <Card style={{ height: '100%' }}>
          <CardHeader title="Details" />
          <Divider />
          <CardContent style={{ height: '100%' }}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <WarehouseSearchField
                  api={API.SALE_ORDER_WAREHOUSE}
                  data-cy="warehouse"
                />
              </Grid>
              <Grid item={true} xs={12}>
                <CompanySearchField disabled={true} />
              </Grid>

              <Grid item={true} xs={12}>
                <DeliveryMethodField />
              </Grid>

              <Grid item={true} xs={12}>
                <ChannelField />
              </Grid>

              <Grid item={true} xs={12}>
                <CurrencyField />
              </Grid>

              <Grid item={true} xs={12}>
                <PaymentTypeUpdateField
                  isLoading={paymentTypeValues.isLoading}
                  onChange={paymentTypeValues.handlePaymentTypeUpdate}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  data-cy="referenceNumber"
                  name="referenceNumber"
                  label="Reference number"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TagsField
                  data-cy="tags"
                  name="tags"
                  label="Tags"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <MoneyField
                  name="shippingFee"
                  currency={currency}
                  label="Shipping fee"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <MoneyField
                  name="extraFees"
                  currency={currency}
                  label="Extra fee"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <DateField
                  data-cy="dueDate"
                  name="dueDate"
                  label="Promise Date"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  data-cy="trackingNumber"
                  name="trackingNumber"
                  label="Tracking number"
                />
              </Grid>

              <Grid item={true} xs={12}>
                <FileAttachment
                  name="attachmentUrl"
                  onUpload={onFileAttach}
                  onRemove={onFileAttachRemove}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

      </Grid>

      <Grid item={true} xs={12}>
        <Card>
          <CardHeader title="Line items" />
          <Divider />
          <LineItems
            currency={currency}
            isLoading={isLoading}
            saleOrderVariant={saleOrderVariant}
            onGetOrderCondition={onGetOrderCondition}
            defaultCondition={defaultCondition}
            setDefaultCondition={setDefaultCondition}
          />
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Invoice summary" />
          <Divider />
          <InvoiceSummary
            shippingFee={shippingFee}
            currency={currency}
            lineItems={lineItems}
            extraFees={extraFees}
            totalPrice={totalPrice}
            updated={updated}
          />
        </Card>
      </Grid>

      <Grid item={true} xs={12} lg={6}>
        <Card>
          <CardHeader title="Customer notes" />
          <Divider />
          <CardContent>
            <TextField
              label="Customer notes"
              name="customerNotes"
              multiline={true}
              minRows={8}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

SaleOrderForm.propTypes = {
  isLoading: PropTypes.bool,
  onCustomerCreate: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  paymentTypeValues: PropTypes.shape({
    handlePaymentTypeUpdate: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
  }).isRequired,
  currency: PropTypes.string,
  saleOrderVariant: PropTypes.object.isRequired,
  onFileAttach: PropTypes.func.isRequired,
  onFileAttachRemove: PropTypes.func.isRequired,
  onGetOrderCondition: PropTypes.func,
  prevLineItems: PropTypes.array,
  defaultCondition: PropTypes.object,
  setDefaultCondition: PropTypes.func,
}

export default SaleOrderForm
