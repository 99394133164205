export const PAGE_REFRESH = 'page_refresh'
export const LINK = 'link'
export const WAREHOUSE_CREATE = 'warehouse_create'
export const PACKING_ASSIGN = 'packing'
export const PUTAWAY = 'putaway'
export const ADJUSTMENT_COMPLETE = 'stock_adjustment_complete'
export const SALE_ORDER_FAILED = 'order.failed'
export const SALE_ORDER_ALLOCATION = 'order.allocation.status'
export const SHIPMENT_CREATED = 'shipment.created'
export const USER_RESET = 'user_token_reset'

// BULK UPLOAD
export const PRODUCT_BULK_UPLOAD_VALIDATION_COMPLETE = 'bulk_upload.product.validation_complete'
export const PRODUCT_BULK_UPLOAD_VALIDATION_STATUS = 'bulk_upload.product.validation_status'
export const PRODUCT_BULK_UPLOAD_CREATE_STATUS = 'bulk_upload.product.creation_status'
export const PRODUCT_BULK_UPLOAD_CREATE_COMPLETE = 'bulk_upload.product.creation_complete'

export const ADJUSTMENT_BULK_VALIDATE_STATUS = 'bulk_upload.stock_adjustment.validate_status'
export const ADJUSTMENT_BULK_VALIDATE_COMPLETE = 'bulk_upload.stock_adjustment.validate_complete'
export const ADJUSTMENT_BULK_CREATE_STATUS = 'bulk_upload.stock_adjustment.create_status'
export const ADJUSTMENT_BULK_CREATE_COMPLETE = 'bulk_upload.stock_adjustment.create_complete'

export const RETURN_LABEL_CREATE_STATUS = 'returns.label.create_status'

export const SHIPMENT_LABEL_CREATE_STATUS = 'shipment.label.create_status'

export const PURCHASE_ORDERS_BULK_VALIDATE_STATUS = 'bulk_upload.purchase_order.validate_status'
export const PURCHASE_ORDERS_BULK_VALIDATE_COMPLETE = 'bulk_upload.purchase_order.validate_complete'
export const PURCHASE_ORDERS_BULK_CREATE_STATUS = 'bulk_upload.purchase_order.create_status'
export const PURCHASE_ORDERS_BULK_CREATE_COMPLETE = 'bulk_upload.purchase_order.create_complete'

export const WAREHOUSE_BULK_VALIDATE_STATUS = 'bulk_upload.warehouse.validation_status'
export const WAREHOUSE_BULK_VALIDATE_COMPLETE = 'bulk_upload.warehouse.validation_complete'
export const WAREHOUSE_BULK_CREATE_STATUS = 'bulk_upload.warehouse.creation_status'
export const WAREHOUSE_BULK_CREATE_COMPLETE = 'bulk_upload.warehouse.creation_complete'

export const SALE_ORDER_BULK_VALIDATE_STATUS = 'bulk_upload.sale_order.validate_status'
export const SALE_ORDER_BULK_VALIDATE_COMPLETE = 'bulk_upload.sale_order.validate_complete'
export const SALE_ORDER_BULK_CREATE_STATUS = 'bulk_upload.sale_order.create_status'
export const SALE_ORDER_BULK_CREATE_COMPLETE = 'bulk_upload.sale_order.create_complete'
export const SALE_ORDER_ALLOCATION_STATUS = 'order.allocation.status'

export const MOVEMENT_BULK_VALIDATE_STATUS = 'bulk_upload.movement.validate_status'
export const MOVEMENT_BULK_VALIDATE_COMPLETE = 'bulk_upload.movement.validate_complete'
export const MOVEMENT_BULK_CREATE_STATUS = 'bulk_upload.movement.create_status'
export const MOVEMENT_BULK_CREATE_COMPLETE = 'bulk_upload.movement.create_complete'

export const SHIPMENT_CANCEL_CARRIER_STATUS = 'shipment.label.cancel_status'

export const SHOPIFY_PRODUCTS_SYNC_STATUS = 'integration.shopify.import_product'

export const PICKING_ASSIGN = 'picking'
export const PICKING_ALTER_UNIT = 'picking.alter_unit'

export const LOT_SHEETS_BULK_VALIDATE_STATUS = 'cross_docking.lotsheet.validation_status'
export const LOT_SHEETS_BULK_VALIDATE_COMPLETE = 'cross_docking.lotsheet.validation_complete'
export const LOT_SHEETS_BULK_CREATE_COMPLETE = 'cross_docking.lotsheet.creation_complete'

export const CITY_MAPPING_BULK_VALIDATE_STATUS = 'bulk_upload.carrier_city_mapping.validation_status'
export const CITY_MAPPING_BULK_VALIDATE_COMPLETE = 'bulk_upload.carrier_city_mapping.validation_complete'
