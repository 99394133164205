import React from 'react'
import { Ordering } from 'storfox-filter'

import { SALE_ORDER_COLUMNS as tableColumns } from './StockCountReportTable'

import { FILTER_NAME as name } from '../../constants/SaleOrder'

export const stockCountReportOrderingOptions = {
  name,
  tableColumns
}

function StockCountReportOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default StockCountReportOrderingForm
