import React from 'react'
import PropTypes from 'prop-types'
import { defaultTo, pipe, prop } from 'ramda'
import isEqual from 'react-fast-compare'
import { Form as FinalForm } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FORM_ERROR } from 'final-form'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import setFieldData from 'final-form-set-field-data'

import ErrorLink from '../ErrorLink/ErrorLink'

export const NONE_FIELD_ERROR = 'nonFieldErrors'

export const getNonFieldError = pipe(
  prop(NONE_FIELD_ERROR),
  defaultTo('Invalid entry data')
)

function Form ({ onSubmit, ...props }) {
  const openSnackbar = useSnackbar()

  const handleSubmit = data => {
    return onSubmit(data)
      .catch(e => {
        const fieldErrors = e
        if (e instanceof Error) {
          throw e
        }
        const nonFieldError = getNonFieldError(fieldErrors)
        fieldErrors && openSnackbar({ type: ALTER_ERROR, message: <ErrorLink error={fieldErrors} /> })

        const error = {
          ...fieldErrors,
          [FORM_ERROR]: nonFieldError
        }
        throw error
      })
  }

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators, setFieldData }}
      onSubmit={handleSubmit}
    />
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default React.memo(Form, isEqual)
