import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { Filter } from 'storfox-filter'
import { prop } from 'ramda'

import { arrayToParams, escapeAtob } from '~/utils'
import Accordion from '~/components/Accordion'
import WarehouseMultiSelectField from '~/components/Fields/WarehouseMultiSelectField'
import * as API from '~/constants/api'

import StockCountField from './StockCountField'

import { FILTER_NAME as name } from '../../constants/SaleOrder'

export const toParams = values => {
  const warehouses = arrayToParams('warehouses', values)
  const stockCount = arrayToParams('stockCount', values)

  return {
    warehouses,
    stockCount
  }
}

export const toValues = params => {
  const warehouses = escapeAtob(prop('warehouses', params))
  const stockCount = escapeAtob(prop('stockCount', params))

  return {
    warehouses,
    stockCount
  }
}
export const transformer = { toParams, toValues }

export const stockCountFilterOptions = {
  name,
  transformer
}

function StockCountReportFilterForm (props) {
  return (
    <Filter {...props}>
      <Accordion title="General" defaultExpanded={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <WarehouseMultiSelectField api={API.SALE_ORDER_WAREHOUSE} />
          </Grid>
          <Grid item={true} xs={12}>
            <StockCountField />
          </Grid>
        </Grid>
      </Accordion>
    </Filter>
  )
}

StockCountReportFilterForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default StockCountReportFilterForm
