import React, { useCallback } from 'react'
import { defaultTo, path, pathOr, prop } from 'ramda'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import { useDeepCompareEffect } from 'storfox-api-hooks'
import useValueChanged from 'storfox-tools/src/hooks/useValueChanged'

import { PROCESSING } from '~/components/Statuses/SaleOrderStatus'
import { useProfile } from '~/components/Profile'
import SkeletonList from '~/components/Skeleton'
import EmptyLineItems from '~/components/EmptyLineItems'
import { emptyArray } from '~/constants/empty'
import { Button } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'
import TextField from '~/components/Fields/TextField'

import LineItem from './LineItem'

import SaleOrderVariantAddDialog from '../SaleOrderVariantAdd/SaleOrderVariantAddDialog'

const NarrowTableCellStyled = styled(TableCell)({
  minWidth: 150
})

function LineItems (props) {
  const { isLoading, currency, saleOrderVariant, onGetOrderCondition, defaultCondition, setDefaultCondition } = props
  const { t } = useTranslation()
  const { profile } = useProfile()
  const { handleClose, handleOpen, open } = useDialog()

  const defaultConditionCode = prop('condition', profile)
  const defaultTax = path(['salesOrderTax', 'value'], profile)
  const currentCompanyGuid = path(['company', 'guid'], profile)

  const statusField = useField('status')
  const status = statusField.input.value
  const isProcess = status === PROCESSING

  const customerField = useField('customer')
  const customer = customerField.input.value

  const warehouseField = useField('warehouse')
  const warehouseGuid = pathOr('', ['input', 'value', 'guid'], warehouseField)

  const companyField = useField('company')
  const companyGuid = pathOr('', ['input', 'value', 'guid'], companyField)

  const searchField = useField('search')
  const searchFieldChange = searchField.input.onChange

  const lineItemsField = useField('lineItems')
  const lineItemsChange = lineItemsField.input.onChange
  const lineItemsError = pathOr('', ['meta', 'submitError', 0], lineItemsField)
  const lineItems = lineItemsField.input.value || emptyArray

  useValueChanged(() => {
    lineItemsChange([])
  }, companyGuid)

  useDeepCompareEffect(() => {
    if (companyGuid) {
      if (currentCompanyGuid !== companyGuid) {
        onGetOrderCondition({ companyGuid }).then((response) => {
          const companyConditionCode = path(['results', 0], response)
          setDefaultCondition(companyConditionCode)
        })
      } else {
        setDefaultCondition(defaultConditionCode)
      }
    }
  }, [profile, currentCompanyGuid, companyGuid])

  const handleVariantsAdd = variants => {
    const newVariants = variants.map(variant => {
      return {
        variant,
        quantity: 1,
        tax: defaultTax,
        discount: 0,
        price: prop('retailPrice', variant),
        imagePath: prop('defaultImage', variant),
        condition: defaultCondition ? { name: defaultCondition.code, ...defaultCondition } : {}
      }
    })

    lineItemsChange([...lineItems, ...newVariants])
    handleClose()
  }

  const handleOnSearch = useCallback(value => {
    searchFieldChange(value)
    handleOpen()
  }, [handleOpen, searchFieldChange])

  const handleOnAddClose = useCallback(() => {
    searchFieldChange('')
    handleClose()
  }, [handleClose, searchFieldChange])

  const isAvailableToSearch = warehouseGuid && companyGuid && customer

  return (
    <FieldArray name="lineItems">
      {({ fields }) => {
        const values = defaultTo([], fields.value)

        const renderLineItems = Boolean(!isLoading && values.length)
        const renderEmptyLineItems = Boolean(!isLoading && !values.length)

        return <>
          <Box sx={{ padding: '16px' }}>
            <Grid container={true} spacing={3}>
              <Grid item={true} lg={10} xs={12}>
                <TextField
                  data-cy="variant"
                  name="variant"
                  label="Search"
                  onChange={event => isAvailableToSearch && handleOnSearch(event.target.value)}
                  disabled={!isAvailableToSearch}
                  error={lineItemsError}
                />
              </Grid>
              <Grid item={true} lg={2} xs={12}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                  type="button"
                  data-cy="browse"
                  disabled={!isAvailableToSearch}
                >
                  Browse
                </Button>
              </Grid>
            </Grid>
            {open && (
              <SaleOrderVariantAddDialog
                onClose={handleOnAddClose}
                open={open}
                saleOrderVariant={saleOrderVariant}
                onAddVariants={handleVariantsAdd}
                params={{ warehouseGuid, companyGuid }}
              />
            )}
          </Box>
          <TableContainer>
            <Table size="small" sx={{ minWidth: '700px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Image')}</TableCell>
                  <TableCell sx={{ minWidth: '220px' }}>{t('Variant')}</TableCell>
                  <NarrowTableCellStyled>{t('Unit price')}</NarrowTableCellStyled>
                  <NarrowTableCellStyled>{t('Qty')}</NarrowTableCellStyled>
                  <NarrowTableCellStyled>{t('Total')}</NarrowTableCellStyled>
                  <NarrowTableCellStyled>{t('Condition')}</NarrowTableCellStyled>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <SkeletonList columnCount={7} />}
                {renderEmptyLineItems && <EmptyLineItems colSpan={7} />}
                {renderLineItems && fields.map((name, index) => (
                  <LineItem
                    key={name}
                    fields={fields}
                    name={name}
                    index={index}
                    currency={currency}
                    isProcess={isProcess}
                    companyGuid={companyGuid}
                  />
                )).reverse()}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }}
    </FieldArray>
  )
}

LineItems.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currency: PropTypes.string,
  saleOrderVariant: PropTypes.object.isRequired,
  onGetOrderCondition: PropTypes.func,
  defaultCondition: PropTypes.object,
  setDefaultCondition: PropTypes.func
}

export default LineItems
