import React from 'react'
import { useFilter, useOrdering } from 'storfox-filter'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import ErrorLink from '~/components/ErrorLink'
import useMessages from '~/hooks/useMessages'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'
import { useTableSelects } from '~/components/TableValues/hooks'
import { queryToParams } from '~/utils'

import { StockCountReportFilterSerializer } from '../../serializers/StockCount'
import { useStockCountReportList, useStockCountReportGenerate } from '../../hooks/StockCount'
import StockCountReportTable from '../../components/StockCountReport/StockCountReportTable'
import
StockCountReportOrderingForm,
{ stockCountReportOrderingOptions }
from '../../components/StockCountReport/StockCountReportOrderingForm'
import
StockCountReportFilterForm,
{ stockCountFilterOptions }
from '../../components/StockCountReport/StockCountReportFilterForm'

function StockCountReportListContainer () {
  const stockCountReportList = useStockCountReportList()
  const stockCountReportGenerate = useStockCountReportGenerate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { sendEvent } = useGoogleEvent()
  const { selects } = useTableSelects()
  const searchParams = useAllSearchParams()

  const filter = useFilter(stockCountFilterOptions)
  const ordering = useOrdering(stockCountReportOrderingOptions)

  const breadcrumbs = { title: NAV.STOCK_COUNT_REPORT }

  const handleSalesExport = () => {
    const params = selects.length === 0
      ? queryToParams(StockCountReportFilterSerializer, searchParams, 'guid')
      : { guids: selects.join(',') }
    return stockCountReportGenerate.generate(params)
      .then(() => {
        sendEvent({ eventAction: 'Report Bulk Generate Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.NOTIFICATION_WAIT })
      })
      .catch(response => {
        const message = <ErrorLink error={response} />
        snackbar({ message, type: ALTER_ERROR })
      })
  }

  return (
    <DashboardLayout
      title={NAV.STOCK_COUNT_REPORT}
      activeNav={NAV.REPORTS}
      breadcrumbs={breadcrumbs}
    >
      <StockCountReportFilterForm {...filter} />
      <StockCountReportOrderingForm {...ordering} />

      <StockCountReportTable
        onListRefetch={stockCountReportList.getListByParams}
        filter={filter}
        ordering={ordering}
        list={stockCountReportList}
        onReportGenerate={handleSalesExport}
      />
    </DashboardLayout>
  )
}

export default StockCountReportListContainer
