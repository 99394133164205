import React from 'react'
import Typography from '@mui/material/Typography'

import * as API from '~/constants/api'
import MultiSelectSearchField from '~/components/Fields/MultiSelectSearchField'

const getOptionValue = value => {
  if (value) {
    const { guid, number } = value
    return { id: guid, guid, name: number }
  }

  return null
}

function StockCountField (props) {
  return (
    <MultiSelectSearchField
      api={API.STOCK_COUNT_LIST}
      name="stockCount"
      label="Stock Count"
      ListboxProps={{ 'data-cy': 'warehousesList' }}
      getOptionValue={getOptionValue}
      renderOption={(props, option) => (
        <li {...props}>
          <Typography>
            {option.name}
          </Typography>
        </li>
      )}
      {...props}
    />
  )
}

export default StockCountField
