import React from 'react'
import { StatusBar } from 'storfox-filter'
import Divider from '@mui/material/Divider'
import FilterListIcon from '@mui/icons-material/FilterList'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import VerticalSplitRoundedIcon from '@mui/icons-material/VerticalSplitRounded'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import TableHeaderLeft from './TableHeaderLeft'
import TableHeaderRight from './TableHeaderRight'

import TableSearchField from '../Fields/TableSearchField'

const RootStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: theme.spacing(6),
  width: '100%',
  background: theme.palette.background.paper,
  borderRadius: '4px',
  borderTop: `1px solid ${theme.palette.background.tableBorder}`,
  borderLeft: `1px solid ${theme.palette.background.tableBorder}`,
  borderRight: `1px solid ${theme.palette.background.tableBorder}`,
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0'
}))

function TableHeader (props) {
  const {
    search = true,
    isLoading = false,
    filter,
    filterOpen,
    orderingOpen,
    onCreateClick,
    ordering,
    children,
    tagSearch = false
  } = props

  const renderStatusBar = filter && ordering

  return (
    <RootStyled>
      <TableHeaderLeft>
        {renderStatusBar && (
          <StatusBar
            filter={filter}
            ordering={ordering}
            isLoading={isLoading}
          />
        )}
      </TableHeaderLeft>
      <TableHeaderRight>
        {onCreateClick && (
          <>
            <IconButton
              title="Create"
              data-cy="create"
              size="small"
              onClick={onCreateClick}
            >
              <AddIcon />
            </IconButton>
            <Box pl={1} />
            <Divider
              orientation="vertical"
              flexItem={true}
              variant="middle"
            />
            <Box pr={1} />
          </>
        )}
        {search && <TableSearchField tagSearch={tagSearch} />}

        {orderingOpen && (
          <>
            <Box pl={1} />
            <Divider
              orientation="vertical"
              flexItem={true}
              variant="middle"
            />
            <Box pr={1} />
            <IconButton
              title="Ordering"
              size="small"
              onClick={orderingOpen}
            >
              <VerticalSplitRoundedIcon />
            </IconButton>
          </>
        )}

        {filterOpen && (
          <>
            <Box pl={1} />
            <Divider
              orientation="vertical"
              flexItem={true}
              variant="middle"
            />
            <Box pr={1} />
            <IconButton
              data-cy="tableFilter"
              title="Filter"
              size="small"
              onClick={filterOpen}
            >
              <FilterListIcon />
            </IconButton>
          </>
        )}

        {children && (
          <>
            <Box pl={1} />
            <Divider
              orientation="vertical"
              flexItem={true}
              variant="middle"
            />
            <Box pr={1} />
            {children}
          </>
        )}

      </TableHeaderRight>
    </RootStyled>
  )
}

TableHeader.propTypes = {
  search: PropTypes.bool,
  isLoading: PropTypes.bool,
  filter: PropTypes.object,
  ordering: PropTypes.object,
  filterOpen: PropTypes.func,
  orderingOpen: PropTypes.func,
  onCreateClick: PropTypes.func,
  children: PropTypes.any,
  tagSearch: PropTypes.bool
}

export default React.memo(TableHeader)
