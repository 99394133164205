import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, orderingToSnackCase } from '~/utils'

export const StockCountReportFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const guids = escapeAtob(prop('stockCount', params))

  return orderingToSnackCase({
    ...defaultParams,
    warehouseGuids,
    guids,
  })
}
