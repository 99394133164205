import React from 'react'
import { useTranslation } from 'react-i18next'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'
import { useNavigate } from 'react-router-dom'
import { prop, propOr } from 'ramda'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'

import ContractCreateWithRetailerForm from '../../components/Contract/ContractCreateWithRetailer'
import { useContractCreateWithRetailer } from '../../hooks/Contract'

const ContractCreateWithRetailerContainer = () => {
  const contractCreate = useContractCreateWithRetailer()
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const navigate = useNavigate()

  const title = t('New contract')
  const message = t('Successfully created')

  const handleSubmit = formValues => {
    const warehouses = propOr([], 'warehouses', formValues)
    const billingProfile = prop('billingProfile', formValues)
    const retailer = prop('retailer', formValues)
    const name = prop('name', retailer)
    const email = prop('email', retailer)
    const password = prop('password', retailer)
    const firstName = prop('firstName', retailer)
    const lastName = prop('lastName', retailer)
    const country = prop('country', retailer)
    const phone = prop('phone', retailer)
    if (warehouses && billingProfile && name && email && password && firstName && lastName && country && phone) {
      return contractCreate.create(formValues)
        .then(() => snackbar({ message }))
        .then(() => navigate(ROUTES.CONTRACT_LIST_PATH))
    } else {
      snackbar({ message: 'All fields are required', type: ALTER_ERROR })
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(null)
    }
  }

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.CONFIGURATION}
      breadcrumbs={breadcrumbs}
    >
      <ContractCreateWithRetailerForm
        pageTitle={title}
        pageTitleLoading={false}
        isLoading={contractCreate.isLoading}
        onSubmit={handleSubmit}
      />

    </DashboardLayout>
  )
}

export default ContractCreateWithRetailerContainer
