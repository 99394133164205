import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { path, prop } from 'ramda'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import { useCompany, useProfile } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'

import { SaleOrderCreateForm } from '../components/SaleOrderCreate'
import { useSaleOrderCreate, useSaleOrderCustomerCreate, useSaleOrderVariant, useOrderConditionList } from '../hooks'
import { SaleOrderCreateInitSerializer, SaleOrderCreateSerializer, SaleOrderCustomerSerializer } from '../serializers'

function SaleOrderCreateContainer () {
  const navigate = useNavigate()
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { company } = useCompany()
  const orderCreate = useSaleOrderCreate()
  const saleOrderCustomerCreate = useSaleOrderCustomerCreate()
  const saleOrderVariant = useSaleOrderVariant()
  const saleOrderCondition = useOrderConditionList()

  const [defaultCondition, setDefaultCondition] = useState({})

  const { profile } = useProfile()
  const { sendEvent } = useGoogleEvent()

  const tax = path(['saleOrderTax', 'value'], profile)
  const condition = prop('condition', profile)
  const warehouses = prop('warehouses', profile)

  const handleCustomerCreate = values =>
    saleOrderCustomerCreate
      .create(SaleOrderCustomerSerializer(values))
      .then(res => {
        sendEvent({ eventAction: 'Customer Create Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleSubmit = formValue =>
    orderCreate.create(SaleOrderCreateSerializer(formValue, defaultCondition))
      .then(data => {
        const guid = path(['result', 'guid'], data)
        const params = { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL }
        const redirect = generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, params)

        navigate(redirect)
      })
      .then(() => {
        sendEvent({ eventAction: 'Create Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.CREATE_SUCCESS })
      })

  const breadcrumbs = { title: NAV.SALE_ORDERS }

  return (
    <DashboardLayout
      title={NAV.SALE_ORDERS}
      activeNav={NAV.SALE_ORDERS}
      isLoading={orderCreate.isLoading}
      breadcrumbs={breadcrumbs}
    >
      <SaleOrderCreateForm
        pageTitle="New Sale Order"
        initialValues={SaleOrderCreateInitSerializer({ warehouses, tax, condition, company })}
        isLoading={orderCreate.isLoading}
        onSubmit={handleSubmit}
        onCustomerCreate={handleCustomerCreate}
        saleOrderVariant={saleOrderVariant}
        onGetOrderCondition={saleOrderCondition.getList}
        defaultCondition={defaultCondition}
        setDefaultCondition={setDefaultCondition}
      />
    </DashboardLayout>
  )
}

export default SaleOrderCreateContainer
