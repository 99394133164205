import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { equals, filter, find, map, prop, propEq, propOr } from 'ramda'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import * as ROUTES from '~/constants/routes'
import { Button, EditButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { useCompany } from '~/components/Profile'
import * as NAV from '~/constants/nav-titles'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import LoaderProvider from '~/components/Loader'
import { CANCELLED, COMPLETED, DRAFT, NEW_ORDER, ON_HOLD, PROCESSING } from '~/components/Statuses/SaleOrderStatus'
import { DELIVERED, RETURNED } from '~/components/Statuses/ShipmentStatus'
import useDialog from '~/hooks/useDialog'
import * as ALLOCATION from '~/components/Statuses/AllocationStatus'
import { UNALLOCATED, ALLOCATED } from '~/components/Statuses/AllocationStatus'

import ManualAllocationDialog from './ManualAllocationDialog'
import SaleOrderHistory from './SaleOrderHistory'
import OrderSplitDialog from './OrderSplitDialog'
import More from './More'
import Notes from './Notes'
import SaleOrderGeneral from './SaleOrderGeneral'
import SaleOrderDetailTabs from './SaleOrderDetailTabs'
import SkipProcessDialog from './SkipProcessDialog'
import SaleOrderPicklistTable from './SaleOrderPicklistTable'
import SaleOrderPackingTable from './SaleOrderPackingTable'
import SaleOrderShipmentTable from './SaleOrderShipmentTable'
import SaleOrderReturnTable from './SaleOrderReturnTable'
import SaleOrderUnitTable from './SaleOrderUnitTable'
import SaleOrderPutawayTable from './SaleOrderPutawayTable'

import { SKIP_PROCESS_KEYS } from '../../constants'

const PROCESS_LIST = [
  { name: 'Picklist', key: SKIP_PROCESS_KEYS.PICKLIST, value: false },
  { name: 'Packing', key: SKIP_PROCESS_KEYS.PACKING, value: false }
]

function SaleOrderDetail (props) {
  const {
    notes,
    onCommentSend,
    orderHistory,
    detail,
    pageTitle,
    pageTitleLoading,
    isLoading,
    onCancel,
    onProcess,
    onResolve,
    onSaleOrderSplit,
    onProcessSkip,
    onMarkAsPaid,
    onInvoiceGenerate,
    warehouseList,
    onAllocate,
    picklist,
    packingList,
    shipmentList,
    returnList,
    unitList,
    putawayList,
    onRelease,
    onPrintInvoice,
    onHealthCareInvoice,
    manualUnitList,
    onManualAllocate
  } = props

  const { guid, tab } = useParams()
  const { isRetailer } = useCompany()

  const [selectedWarehouseGuids, setSelectedWarehouseGuids] = useState([])
  const [processList, setProcessList] = useState(PROCESS_LIST)

  const orderSplitDialog = useDialog()
  const manualAllocationDialog = useDialog()
  const processSkipDialog = useDialog()

  const navigate = useNavigate()

  const status = prop('status', detail)

  const company = propOr({}, 'company', detail)

  const disabled = isLoading

  const editPath = generatePath(ROUTES.SALE_ORDER_UPDATE_PATH, { guid })

  const processDisabled = (
    pageTitleLoading ||
    isLoading ||
    status === PROCESSING ||
    status === COMPLETED ||
    status === CANCELLED
  )

  const processSkipDisabled = (
    disabled ||
    status !== NEW_ORDER
  )

  const shipmentStatus = prop('shipmentStatus', detail)
  const allocationStatus = prop('allocationStatus', detail)
  const shipmentCount = propOr(0, 'shipmentCount', detail)

  const cancelDisabled = (
    isLoading ||
    shipmentStatus === DELIVERED ||
    shipmentStatus === RETURNED ||
    status === CANCELLED
  )

  const handleReturn = () => {
    const path = generatePath(ROUTES.RETURN_CREATE_PATH, { guid })
    return navigate(path)
  }

  const handleOrderSplitOpen = () => {
    orderSplitDialog.handleOpen()
    warehouseList.getList()
  }

  const handleProcessSkipOpen = () => {
    processSkipDialog.handleOpen()
  }

  const handleCommentSend = values => {
    const comment = prop('comment', values)
    return onCommentSend(comment)
  }

  const handleProcessesSelect = key => {
    const newProcessList = map(process =>
      propEq('key', key, process)
        ? { ...process, value: !process.value }
        : process
    , processList)

    setProcessList(newProcessList)
  }

  const handleWarehouseSelect = guid => {
    const channel = find(equals(guid), selectedWarehouseGuids)
    if (channel) {
      const newSelectedWarehouses = filter(warehouseGuid => warehouseGuid !== guid, selectedWarehouseGuids)
      setSelectedWarehouseGuids(newSelectedWarehouses)
    } else {
      setSelectedWarehouseGuids([...selectedWarehouseGuids, guid])
    }
  }

  const handleSaleOrderSplit = () =>
    onSaleOrderSplit(selectedWarehouseGuids)
      .then(() => orderSplitDialog.handleClose())

  const handleProcessSkipClose = () => {
    setProcessList(PROCESS_LIST)
    processSkipDialog.handleClose()
  }

  const handleProcessSkip = () =>
    onProcessSkip(processList)
      .then(() => handleProcessSkipClose())
      .then(() => setProcessList(PROCESS_LIST))

  const reallocationStatus = (
    allocationStatus === ALLOCATION.UNALLOCATED ||
    allocationStatus === ALLOCATION.ALLOCATION_PENDING ||
    allocationStatus === ALLOCATION.PARTIALLY_ALLOCATED ||
    allocationStatus === ALLOCATION.FAILED
  )

  const releaseStatus = (
    allocationStatus === ALLOCATION.ALLOCATED ||
    allocationStatus === ALLOCATION.PARTIALLY_ALLOCATED
  )

  const handleManualAllocate = (values) => onManualAllocate(values).then(() => manualAllocationDialog.handleClose())

  return (
    <LoaderProvider isLoading={isLoading}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={pageTitle}
            pageTitleLoading={pageTitleLoading}
            parentTitle={NAV.SALE_ORDERS}
            rightButton={(
              <>
                {status === DRAFT && (
                  <Button
                    data-cy="allocate"
                    variant="contained"
                    disabled={processDisabled}
                    onClick={onAllocate}
                  >
                    Allocate
                  </Button>
                )}
                {releaseStatus && status === NEW_ORDER && (
                  <Button
                    data-cy="unallocate"
                    variant="contained"
                    onClick={onRelease}
                  >
                    Unallocate
                  </Button>
                )}
                {status !== ON_HOLD && status !== DRAFT && reallocationStatus && (
                  <Button
                    data-cy="reallocate"
                    variant="contained"
                    disabled={processDisabled}
                    onClick={onAllocate}
                  >
                    Reallocate
                  </Button>
                )}
                {status !== ON_HOLD && status !== DRAFT && !reallocationStatus && (
                  <Button
                    data-cy="process"
                    variant="contained"
                    disabled={processDisabled}
                    onClick={onProcess}
                  >
                    Process
                  </Button>
                )}
                {status === ON_HOLD && (
                  <Button
                    variant="contained"
                    data-cy="resolve"
                    disabled={disabled}
                    onClick={onResolve}
                  >
                    Resolve
                  </Button>
                )}
                <EditButton path={editPath} disabled={disabled} />
                <More
                  cancelDisabled={cancelDisabled}
                  invoiceGenerateDisabled={status === CANCELLED}
                  markAsPaidDisabled={disabled}
                  returnDisabled={status !== COMPLETED}
                  saleOrderSplitDisabled={disabled}
                  processSkipDisabled={processSkipDisabled}
                  onCancelClick={onCancel}
                  onInvoiceGenerateClick={onInvoiceGenerate}
                  onMarkAsPaidClick={onMarkAsPaid}
                  onReturnClick={handleReturn}
                  onSaleOrderSplitClick={handleOrderSplitOpen}
                  onProcessSkipClick={handleProcessSkipOpen}
                  commercialDisabled={!shipmentCount}
                  onCommercialGenerate={onPrintInvoice}
                  onManualAllocate={() => manualAllocationDialog.handleOpen()}
                  manualEnabled={status === NEW_ORDER && allocationStatus === UNALLOCATED}
                  onHealthCareInvoice={onHealthCareInvoice}
                  healthCareDisabled={allocationStatus !== ALLOCATED}
                />
              </>
            )}
          />
        </Header>
        <Content>
          <SaleOrderDetailTabs value={tab} detail={detail} />
          {tab === SALE_ORDER_DETAIL_TABS.GENERAL && (
            <SaleOrderGeneral
              detail={detail}
              isLoading={pageTitleLoading}
              company={company}
              isRetailer={isRetailer}
            />
          )}

          {tab === SALE_ORDER_DETAIL_TABS.NOTES && (
            <Notes
              notes={notes}
              onCommentSend={onCommentSend}
              onSubmit={handleCommentSend}
            />
          )}

          {tab === SALE_ORDER_DETAIL_TABS.UNIT && <SaleOrderUnitTable unitList={unitList} />}
          {tab === SALE_ORDER_DETAIL_TABS.PICKLIST && <SaleOrderPicklistTable picklist={picklist} />}
          {tab === SALE_ORDER_DETAIL_TABS.PACKING && <SaleOrderPackingTable packingList={packingList} />}
          {tab === SALE_ORDER_DETAIL_TABS.SHIPMENT && <SaleOrderShipmentTable shipmentList={shipmentList} />}
          {tab === SALE_ORDER_DETAIL_TABS.RETURN && <SaleOrderReturnTable returnList={returnList} />}
          {tab === SALE_ORDER_DETAIL_TABS.PUTAWAY && <SaleOrderPutawayTable putawayList={putawayList} />}
          {tab === SALE_ORDER_DETAIL_TABS.HISTORY && <SaleOrderHistory history={orderHistory} />}
        </Content>
      </Container>
      <OrderSplitDialog
        open={orderSplitDialog.open}
        onClose={orderSplitDialog.handleClose}
        onSubmit={handleSaleOrderSplit}
        onWarehouseSelect={handleWarehouseSelect}
        warehouseList={warehouseList.results}
        isLoading={warehouseList.isLoading}
        selectedWarehouseGuids={selectedWarehouseGuids}
      />
      <ManualAllocationDialog
        onSubmit={handleManualAllocate}
        onClose={manualAllocationDialog.handleClose}
        open={manualAllocationDialog.open}
        initialValues={{}}
        manualUnitList={manualUnitList}
      />
      <SkipProcessDialog
        onProcessSelect={handleProcessesSelect}
        open={processSkipDialog.open}
        processList={processList}
        onClose={handleProcessSkipClose}
        onSubmit={handleProcessSkip}
      />
    </LoaderProvider>
  )
}

SaleOrderDetail.propTypes = {
  detail: PropTypes.object.isRequired,
  orderHistory: PropTypes.object.isRequired,
  notes: PropTypes.object.isRequired,
  onCommentSend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onMarkAsPaid: PropTypes.func.isRequired,
  onProcess: PropTypes.func.isRequired,
  warehouseList: PropTypes.object.isRequired,
  onResolve: PropTypes.func.isRequired,
  onSaleOrderSplit: PropTypes.func.isRequired,
  onInvoiceGenerate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageTitle: PropTypes.object.isRequired,
  pageTitleLoading: PropTypes.bool.isRequired,
  onAllocate: PropTypes.func.isRequired,
  onProcessSkip: PropTypes.func.isRequired,
  picklist: PropTypes.object.isRequired,
  packingList: PropTypes.object.isRequired,
  shipmentList: PropTypes.object.isRequired,
  returnList: PropTypes.object.isRequired,
  unitList: PropTypes.object.isRequired,
  putawayList: PropTypes.object.isRequired,
  onRelease: PropTypes.func.isRequired,
  onPrintInvoice: PropTypes.func.isRequired,
  manualUnitList: PropTypes.object,
  onHealthCareInvoice: PropTypes.func,
  onManualAllocate: PropTypes.func
}

export default SaleOrderDetail
