import { sprintf } from 'sprintf-js'
import { usePickSearchParams } from 'storfox-route-hooks'
import { useCreate, useDelete, useDetail, useLimitedCountList, useUpdate } from 'storfox-api-hooks'

import { orderingToSnackCase } from '~/utils'
import * as API from '~/constants/api'

export const useCityMappingList = () => {
  const searchParams = usePickSearchParams()
  return useLimitedCountList(API.CITY_MAPPING_LIST, orderingToSnackCase(searchParams))
}

export const useCityMappingDetail = guid => {
  const url = sprintf(API.CITY_MAPPING_DETAIL, guid)
  return useDetail(url)
}

export const useCityMappingCreate = () => {
  return useCreate(API.CITY_MAPPING_CREATE)
}

export const useCityMappingUpdate = (guid) => {
  const url = sprintf(API.CITY_MAPPING_UPDATE, guid)
  return useUpdate(url)
}

export const useCityMappingDelete = (id) => {
  const url = sprintf(API.CITY_MAPPING_DELETE, id)
  return useDelete(url)
}

export const useCityMappingImport = () => {
  return useCreate(API.CITY_MAPPING_FILE_UPLOAD)
}
