import React, { useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { path, prop, propOr } from 'ramda'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import Title from '~/components/Title'
import ErrorLink from '~/components/ErrorLink'
import { SALE_ORDER_DETAIL_TABS } from '~/constants/tabs'
import * as NAV from '~/constants/nav-titles'
import useMessages from '~/hooks/useMessages'
import * as ROUTES from '~/constants/routes'
import { useProfile } from '~/components/Profile'
import { useGoogleEvent } from '~/components/GoogleAnalytics/GoogleAnalytics'

import SaleOrderUpdateForm from '../components/SaleOrderUpdate'
import {
  usePaymentTypeSet,
  useSaleOrderCustomerCreate,
  useSaleOrderDetail,
  useSaleOrderUpdate,
  useSaleOrderVariant,
  useWarehouseList,
  useSaleOrderFileAttach,
  useSaleOrderFileAttachRemove,
  useOrderConditionList
} from '../hooks'
import {
  PaymentTypeSetSerializer,
  SaleOrderCreateSerializer,
  SaleOrderCustomerSerializer,
  SaleOrderUpdateInitSerializer
} from '../serializers'

function SaleOrderUpdateContainer () {
  const { guid } = useParams()
  const messages = useMessages()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const { profile } = useProfile()
  const { sendEvent } = useGoogleEvent()

  const tax = path(['saleOrderTax', 'value'], profile)
  const condition = prop('condition', profile)

  const saleOrderDetail = useSaleOrderDetail(guid)
  const saleOrderUpdate = useSaleOrderUpdate(guid)
  const saleOrderFileAttach = useSaleOrderFileAttach(guid)
  const saleOrderFileAttachRemove = useSaleOrderFileAttachRemove(guid)
  const paymentTypeSet = usePaymentTypeSet()
  const warehouseList = useWarehouseList()
  const saleOrderCustomerCreate = useSaleOrderCustomerCreate()
  const saleOrderVariant = useSaleOrderVariant()
  const saleOrderCondition = useOrderConditionList()

  const [defaultCondition, setDefaultCondition] = useState({})

  const handleCustomerCreate = values =>
    saleOrderCustomerCreate
      .create(SaleOrderCustomerSerializer(values))
      .then(res => {
        sendEvent({ eventAction: 'Customer Create Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.CREATE_SUCCESS })
        return res
      })

  const handleSubmit = formValue =>
    saleOrderUpdate.update(SaleOrderCreateSerializer(formValue, defaultCondition))
      .then(() => {
        sendEvent({ eventAction: 'Update Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .then(() => {
        const params = { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL }
        const redirect = generatePath(ROUTES.SALE_ORDER_DETAIL_PATH, params)

        navigate(redirect)
      })

  const handleFileAttach = (url) =>
    saleOrderFileAttach.attach({ url })
      .then(() => {
        sendEvent({ eventAction: 'File Attach Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.ATTACH_SUCCESS })
      })

  const handleFileAttachRemove = () =>
    saleOrderFileAttachRemove.create()
      .then(() => {
        sendEvent({ eventAction: 'Attach Remove Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.ATTACH_REMOVE_SUCCESS })
      })

  const handlePaymentTypeUpdate = paymentType =>
    paymentTypeSet.set(PaymentTypeSetSerializer(guid, paymentType))
      .then(() => {
        sendEvent({ eventAction: 'Payment Update Sale Order', eventCategory: 'Sale Order' })
        snackbar({ message: messages.UPDATE_SUCCESS })
      })
      .catch(error => snackbar({
        type: ALTER_ERROR,
        message: <ErrorLink error={error} />
      }))

  const paymentTypeValues = {
    handlePaymentTypeUpdate,
    isLoading: paymentTypeSet.isLoading
  }

  const saleOrderNumber = prop('number', saleOrderDetail.detail)
  const referenceNumber = prop('referenceNumber', saleOrderDetail.detail)
  const number = referenceNumber ? `${saleOrderNumber} / Reference Number ${referenceNumber}` : saleOrderNumber
  const title = saleOrderDetail.isLoading ? 'Sale orders' : number

  const pageTitle = (
    <Title title="Sale Order" number={number} />
  )

  const isLoading = saleOrderDetail.isLoading || saleOrderUpdate.isLoading

  const breadcrumbs = {
    title: 'Edit',
    params: [
      { guid, tab: SALE_ORDER_DETAIL_TABS.GENERAL, title: number }
    ]
  }
  const initialValues = SaleOrderUpdateInitSerializer(saleOrderDetail.detail, { tax, condition })
  const lineItems = propOr([], 'lineItems', initialValues)

  return (
    <DashboardLayout
      title={title}
      activeNav={NAV.SALE_ORDERS}
      isLoading={isLoading}
      breadcrumbs={breadcrumbs}
    >
      <SaleOrderUpdateForm
        pageTitle={pageTitle}
        pageTitleLoading={saleOrderDetail.isLoading}
        isLoading={isLoading}
        warehouseList={warehouseList}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onCustomerCreate={handleCustomerCreate}
        paymentTypeValues={paymentTypeValues}
        saleOrderVariant={saleOrderVariant}
        onFileAttach={handleFileAttach}
        onFileAttachRemove={handleFileAttachRemove}
        onGetOrderCondition={saleOrderCondition.getList}
        lineItems={lineItems}
        defaultCondition={defaultCondition}
        setDefaultCondition={setDefaultCondition}
      />
    </DashboardLayout>
  )
}

export default SaleOrderUpdateContainer
