import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { defaultTo, find, path, pathOr, prop, propEq, propOr, reduce } from 'ramda'
import Grid from '@mui/material/Grid'
import { useField } from 'react-final-form'
import DialogActions from '@mui/material/DialogActions'
import { ALTER_ERROR, useSnackbar } from 'storfox-snackbar'

import DialogTitle from '~/components/DialogTitle'
import TextField from '~/components/Fields/TextField'
import { withForm } from '~/components/Form'
import { ScannerDialogContent, ScannerDialogTitle } from '~/components/ScannerDialog'
import MobileFullWidthDialog from '~/components/MobileFullWidthDialog'
import { Button, DiscardButton } from '~/components/Buttons'
import useDialog from '~/hooks/useDialog'

import BarcodeField from './BarcodeField'
import BarcodeList from './BarcodeList'
import BarcodeInformation from './BarcodeInformation'

import AlterPickingDialog from '../AlterPickingDialog'

const getCount = reduce((acc, item) => {
  const quantity = propOr(1, 'quantity', item)
  return acc + quantity
}, 0)

function PickingDialogContent (props) {
  const {
    form,
    open,
    onClose,
    isLoading,
    onUnitAlter,
    alterLoading
  } = props

  const { initialValues, handleSubmit } = form
  const alterUnitDialog = useDialog()
  const snackbar = useSnackbar()

  const unitNumberRef = useRef(null)
  const containerNumberRef = useRef(null)

  const useNumberField = useField('unitNumber')
  const containerNumberField = useField('containerNumber')
  const lineItemsField = useField('lineItems')

  const lineItems = defaultTo([], lineItemsField.input.value)

  const unit = prop('unit', initialValues)
  const pickedQuantity = prop('pickedQuantity', initialValues)
  const unitQuantity = prop('quantity', unit)
  const unitNumber = prop('unitNumber', unit)
  const serialNumber = prop('serialNumber', unit)

  const variant = prop('variant', unit)
  const title = prop('name', variant)
  const image = prop('defaultImage', variant)

  const sku = prop('sku', variant)
  const barcode = prop('barcode', variant)
  const fromContainer = pathOr('', ['container', 'number'], unit)
  const fromLocation = pathOr('', ['location', 'locationId'], unit)
  const trackSerial = prop('serialNumber', unit)

  const count = getCount(lineItems) + pickedQuantity
  const isListFull = count >= unitQuantity
  const unitNumberFieldDisabled = isLoading || isListFull
  const alterUnitsDisabled = isListFull || isLoading
  const disabled = containerNumberField.input.value.length === 0 || isLoading

  const focusUnitNumberField = useCallback(() => {
    if (unitNumberRef.current) {
      unitNumberRef.current.focus()
    }
  }, [])

  useEffect(() => {
    const allLineItemsScanned = getCount(lineItems) >= unitQuantity
    if (!unitNumberFieldDisabled && !allLineItemsScanned) {
      focusUnitNumberField()
    }
  }, [focusUnitNumberField, lineItems, unitQuantity, unitNumberFieldDisabled])

  useEffect(() => {
    const allLineItemsScanned = getCount(lineItems) >= unitQuantity
    if (containerNumberRef.current && allLineItemsScanned) {
      containerNumberRef.current.focus()
    }
  }, [lineItems, unitQuantity])

  useEffect(() => {
    const prevContainer = path(['container', 'number'], initialValues)
    if (prevContainer) {
      containerNumberField.input.onChange(prevContainer)
    }
  }, [containerNumberField.input, initialValues])

  const handleChange = value => {
    if (unitNumber === value) {
      const foundNotFullUnitNumber = find(item => {
        const matchUnit = propEq('unitNumber', value, item)
        const notFullyScanned = prop('quantity', item) < prop('totalQuantity', item)

        return matchUnit && notFullyScanned
      }, lineItems)

      if (foundNotFullUnitNumber) {
        const newLineItem = { ...lineItems[0], quantity: lineItems[0].quantity + 1 }
        lineItemsField.input.onChange([newLineItem])
      } else {
        const values = {
          unitNumber,
          serialNumber,
          conditionCode: path(['condition', 'code'], unit),
          quantity: 1,
          totalQuantity: prop('quantity', unit)
        }

        lineItemsField.input.onChange([values, ...lineItems])
      }
    } else {
      snackbar({ message: 'Wrong Unit Number', type: ALTER_ERROR })
    }
    useNumberField.input.onChange('')
    unitNumberRef.current.focus()
  }

  const handleUnitAlter = (values) => {
    onUnitAlter(values).then(() => alterUnitDialog.handleClose())
  }

  return (
    <MobileFullWidthDialog open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <ScannerDialogTitle
            title={title}
            sku={sku}
            barcode={barcode}
            image={image}
            quantity={unitQuantity}
            count={count}
          />
        </DialogTitle>

        <ScannerDialogContent>
          <Grid item={true} xs={12}>
            <BarcodeInformation
              list={lineItems}
              fromContainer={fromContainer}
              fromLocation={fromLocation}
              trackSerial={trackSerial}
              unitNumber={unitNumber}
              serialNumber={serialNumber}
            />
          </Grid>

          <Grid item={true} lg={7} xs={12}>
            <BarcodeField
              ref={unitNumberRef}
              name="unitNumber"
              data-cy="unitNumber"
              label="Scan unit number"
              autoFocus={true}
              onChange={handleChange}
              disabled={unitNumberFieldDisabled}
            />
          </Grid>

          <Grid item={true} lg={5} xs={12}>
            <TextField
              inputProps={{ ref: containerNumberRef }}
              disabled={pickedQuantity}
              name="containerNumber"
              data-cy="containerNumber"
              label="Scan container ID"
              error={containerNumberField.meta.invalid}
              helperText={(
                containerNumberField.meta.submitError ||
                containerNumberField.meta.error
              )}
            />
          </Grid>

          <Grid item={true} xs={12}>
            <BarcodeList />
          </Grid>
        </ScannerDialogContent>

        <DialogActions>
          <DiscardButton
            onClick={onClose}
            fullWidth={true}
          >
            Discard
          </DiscardButton>

          <Button
            fullWidth={true}
            variant="contained"
            onClick={alterUnitDialog.handleOpen}
            disabled={alterUnitsDisabled}
            data-cy="alterUnit"
          >
            Alter units
          </Button>

          <Button
            disabled={disabled}
            data-cy="done"
            variant="contained"
            fullWidth={true}
            type="submit"
          >
            Done
          </Button>
        </DialogActions>
      </form>
      {alterUnitDialog.open && (
        <AlterPickingDialog
          open={alterUnitDialog.open}
          initialValues={initialValues}
          handleClose={alterUnitDialog.handleClose}
          onSubmit={handleUnitAlter}
          isLoading={alterLoading}
        />
      )}
    </MobileFullWidthDialog>
  )
}

PickingDialogContent.propTypes = {
  onUnitAlter: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  alterLoading: PropTypes.bool
}

export default withForm(PickingDialogContent)
