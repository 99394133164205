import React, { useMemo } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { find, map, propEq } from 'ramda'
import { useTranslation } from 'react-i18next'

import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import TileCard, { TileCardLink } from '~/components/Cards/TileCard'
import Accordion from '~/components/Accordion'
import { useCompany } from '~/components/Profile'

const ALL_REPORTS = [
  {
    name: NAV.STOCK_AGING_REPORT,
    path: ROUTES.REPORT_STOCK_AGING_LIST_PATH
  },
  {
    name: NAV.SALES_MARGIN_REPORT,
    path: ROUTES.REPORT_SALES_MARGIN_LIST_PATH
  },
  {
    name: NAV.TPL_BILLING,
    path: ROUTES.TPL_BILLING_LIST_PATH
  },
  {
    name: NAV.SALES_OVERVIEW,
    path: ROUTES.SALES_OVERVIEW_LIST_PATH
  },
  {
    name: NAV.TEAM_METRICS,
    path: ROUTES.TEAM_METRICS_LIST_PATH
  },
  {
    name: NAV.STORAGE_REPORT,
    path: ROUTES.STORAGE_REPORT_PATH
  },
  {
    name: NAV.SALE_ORDER_REPORT,
    path: ROUTES.SALE_ORDER_REPORT_LIST_PATH
  },
  {
    name: NAV.INVENTORY_REPORT,
    path: ROUTES.INVENTORY_REPORT_LIST_PATH
  },
  {
    name: NAV.SHIPMENT_REPORT,
    path: ROUTES.SHIPMENT_REPORT_LIST_PATH
  },
  {
    name: NAV.UNIT_REPORT,
    path: ROUTES.UNIT_REPORT_LIST_PATH
  },
  {
    name: NAV.UNIT_HISTORY,
    path: ROUTES.UNIT_HISTORY_REPORT_LIST_PATH
  },
  {
    name: NAV.PURCHASE_REPORT,
    path: ROUTES.PURCHASE_REPORT_LIST_PATH
  },
  {
    name: NAV.ORDER_UNITS_REPORT,
    path: ROUTES.ORDER_UNITS_REPORT_LIST_PATH
  },
  {
    name: NAV.RETAILER_UNITS_REPORT,
    path: ROUTES.RETAILER_UNIT_REPORT_LIST_PATH
  },
  {
    name: NAV.LOCATION_UNITS_REPORT,
    path: ROUTES.LOCATION_UNIT_REPORT_LIST_PATH
  },
  {
    name: NAV.STOCK_COUNT_REPORT,
    path: ROUTES.STOCK_COUNT_REPORT_LIST_PATH
  }
]

const RETAILER_REPORTS = [
  NAV.STOCK_AGING_REPORT,
  NAV.SALES_MARGIN_REPORT,
  NAV.TPL_BILLING,
  NAV.SALES_OVERVIEW,
  NAV.STORAGE_REPORT,
  NAV.SALE_ORDER_REPORT,
  NAV.INVENTORY_REPORT,
  NAV.SHIPMENT_REPORT,
  NAV.UNIT_REPORT,
  NAV.UNIT_HISTORY,
  NAV.PURCHASE_REPORT,
  NAV.ORDER_UNITS_REPORT,
  NAV.RETAILER_UNITS_REPORT,
  NAV.LOCATION_UNITS_REPORT
]

const TPL_REPORTS = [
  NAV.STOCK_AGING_REPORT,
  NAV.SALES_MARGIN_REPORT,
  NAV.TPL_BILLING,
  NAV.SALES_OVERVIEW,
  NAV.TEAM_METRICS,
  NAV.STORAGE_REPORT,
  NAV.SALE_ORDER_REPORT,
  NAV.INVENTORY_REPORT,
  NAV.SHIPMENT_REPORT,
  NAV.UNIT_REPORT,
  NAV.UNIT_HISTORY,
  NAV.PURCHASE_REPORT,
  NAV.ORDER_UNITS_REPORT,
  NAV.RETAILER_UNITS_REPORT,
  NAV.LOCATION_UNITS_REPORT,
  NAV.STOCK_COUNT_REPORT
]

const getRoutes = map(name => find(propEq('name', name), ALL_REPORTS))

function ReportList () {
  const { t } = useTranslation()
  const { isRetailer } = useCompany()

  const reports = useMemo(
    () => isRetailer ? getRoutes(RETAILER_REPORTS) : getRoutes(TPL_REPORTS)
    , [isRetailer])

  return (
    <Box sx={{ padding: '10px 32px 20px 32px' }}>
      <Accordion title="Basic" defaultExpanded={true}>
        <Grid container={true} spacing={3}>
          {reports.map(({ name, path }) => (
            <Grid item={true} sm={12} lg={2} key={name}>
              <TileCard>
                <TileCardLink
                  to={path}
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography component="span" variant="h6">
                    {t(name)}
                  </Typography>
                </TileCardLink>
              </TileCard>
            </Grid>
          ))}
        </Grid>
      </Accordion>
    </Box>
  )
}

export default ReportList
