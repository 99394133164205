import { useLimitedCountList, useCreate } from 'storfox-api-hooks'
import useAllSearchParams from 'storfox-route-hooks/src/useAllSearchParams'

import { queryToParams } from '~/utils'
import * as API from '~/constants/api'

import { StockCountReportFilterSerializer } from '../../serializers/StockCount'

export const useStockCountReportList = () => {
  const { select, ...searchParams } = useAllSearchParams()
  const params = queryToParams(StockCountReportFilterSerializer, searchParams, 'guid')

  const { getList, ...state } = useLimitedCountList(API.STOCK_COUNT_REPORT_LIST, { ...params })
  return { ...state, getList: () => getList(params) }
}

export const useStockCountReportGenerate = () => {
  const { create, ...state } = useCreate(API.STOCK_COUNT_REPORT_GENERATE)
  return { ...state, generate: create }
}
